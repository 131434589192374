export const template = `<div
  class="ds-field"
  sc-form-group-validity-classes="sc-form-group-validity-classes"
>
  <div class="ds-input --datetime">
    <input
      type="{{$ctrl.inputType}}"
      name="{{ $ctrl.dateFieldName }}"
      size="{{ $ctrl.dateFieldSize || &quot;10&quot; }}"
      data-date-format="{{ &quot;date_time_picker.date_format&quot; | translate }}"
      min="{{ $ctrl.minDateTime }}"
      max="{{ $ctrl.maxDateTime }}"
      placeholder="{{ &quot;date_time_picker.date&quot; | translate }}"
      ng-model="$ctrl.selectedDateTime"
      ng-change="$ctrl.updateDateTime()"
      ng-required="$ctrl.required"
    />
  </div>
  <div
    class="ds-text --addon"
    ng-if="!$ctrl.noTimeZone"
  >
    {{ $ctrl.timezone }}
  </div>
</div>`;
