import { ModalAppStateAttributeTracker } from '@ts/app-body-attribute-utils';
import { BaseController } from './base_controller';

const bootStrapModalUniquePrefix = 'legacy-bootstrap-modal';
let bootstrapModalUniqueCounter = 0;

/**
 * A base class for component controllers, when the component
 * is designed for presentation in a modal.
 */
export class BaseModalController extends BaseController {
  private readonly modalUniquePrefix: string;
  constructor($log: ng.ILogService) {
    super($log);
    this.modalUniquePrefix = bootStrapModalUniquePrefix + '-' + bootstrapModalUniqueCounter++;
    ModalAppStateAttributeTracker.addModalAppState(this.modalUniquePrefix);
    this.trace('BaseModalController: Hello, I am being constructed...');
  }

  /**
   * The `modalInstance` property is a modal instance, provided by `$uibModal`. This property
   * will be set to a reference to the modal instance containing the component, if the
   * following two requirements are met (as they should be, if you are using `BaseModalController`):
   *
   * 1.) the receiver is a controller constructed via `$uibModal.open({component: ...`
   *
   * 2.) the actual Angular component managed by the controller has the expected bindings:
   *
   *         public bindings = {
   *           modalInstance: '<',
   *           resolve: '<',
   *           // ... plus whatever else...
   *         };
   *
   * FIXME: seems like this should be typed ng.ui.bootstrap.IModalServiceInstance, but
   * I cannot make that work... (How to import properly?)
   */
  modalInstance: any;

  /**
   * The `resolve` property is the $uibModal mechanism to pass incoming data to a modal.
   * When a modal is created in the normal way similiar to the code below, then once
   * the modal is created and has animated into view, the $uibModal machinery will
   * take care of populating the `resolve` object.
   *
   * Subclasses can access the `resolve` data from within `$onInit()` or any time after.
   *
   * **Example code for creating modal:**
   *
   *    $uibModal.open({
   *      component: 'scFooComponent',
   *      resolve: {params: () => {return {foo: bar};}},
   *      //...
   *
   * FIXME: Mason 2018-06-2s: Hey, make this base class import $uibModal and deal with it, and add a UI that wraps the above in convenient function so that calling code need not import $uibModal.
   */
  resolve: any;

  /**
   *  Convenience method that returns resolve.params, or {} if nonexistent.
   *
   *  Use it like: `let foo = this.params().foo;`
   */
  params(): any {
    return (this.resolve && this.resolve.params) || {};
    // FIXME: update subclasses to use this so they dont need to: this.resolve && this.resolve.params
  }

  /**
   * Private method to catch errors caused by bindings being incorrectly set up.
   */
  private validateModalInstance() {
    if (!this.modalInstance) {
      this.error('Internal error: BaseModalController has no modalInstance property defined.');
      return false;
    }
    if (typeof this.modalInstance.close !== 'function' || typeof this.modalInstance.dismiss !== 'function') {
      this.error('Internal error: BaseModalController has an invalid modalInstance property.');
      return false;
    }
    return true;
  }

  /**
   * Invokes `this.modalInstance.close(result)`, which is the canonical way to
   * close a $uibModal and pass the result to the calling context (that created)
   * the modal.
   *
   * Typically overrides should call super.
   *
   * @param result - anything at all
   */
  close(result?: any) {
    if (this.validateModalInstance()) {
      ModalAppStateAttributeTracker.removeModalAppState(this.modalUniquePrefix);
      this.modalInstance.close(result);
    }
  }

  /**
   * Invokes `this.modalInstance.dismiss(reason)`, which is the canonical way to
   * dismiss a $uibModal and pass the reason to the calling context (that created
   * the modal).
   *
   * Note that $uibModal itself may call dismiss(), e.g. when Escape key is pressed.
   *
   * Typically overrides should call super.
   *
   * @param result - anything at all
   */
  dismiss(reason?: any) {
    if (this.validateModalInstance()) {
      ModalAppStateAttributeTracker.removeModalAppState(this.modalUniquePrefix);
      this.modalInstance.dismiss(reason);
    }
  }
}
