<form [formGroup]="form" (submit)="onSubmit()">
  <div class="ds-rows">
    <label class="ds-field">
      <div class="ds-text --label">
        {{ 'data.global_options.latitude.label' | translate }}
        <i
          class="ds-icon --xxsmall --icon-question --top-right"
          [attr.data-ds-texttip]="'data.global_options.latitude.description' | translate"
        ></i>
      </div>
      <input
        type="text"
        class="ds-input"
        formControlName="latitudeKey"
        [placeholder]="'data.global_options.latitude.placeholder' | translate"
      />
    </label>
    <label class="ds-field">
      <div class="ds-text --label">
        {{ 'data.global_options.longitude.label' | translate }}
        <i
          class="ds-icon --xxsmall --icon-question --top-right"
          [attr.data-ds-texttip]="'data.global_options.longitude.description' | translate"
        ></i>
      </div>
      <input
        type="text"
        class="ds-input"
        formControlName="longitudeKey"
        [placeholder]="'data.global_options.longitude.placeholder' | translate"
      />
    </label>
    <button type="submit" class="ds-button --primary" [disabled]="!form.valid">
      <span>{{ 'data.global_options.apply' | translate }}</span>
    </button>
  </div>
</form>
