import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Logger } from '@soracom/shared-ng/logger-service';
import { SoracomApiService } from '../../../../app/shared/components/soracom_api.service';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { AbstractController } from '@soracom/shared-ng/soracom-ui-legacy';
import { DateRange } from '../../soracom-ui/DateRange';
import { TimeRange } from '@soracom/shared-ng/ui-common';
import { defaultDownloadTimeRange, LogViewerDateTimeRanges } from '../LogViewerTimeRanges';
import { ModalAppStateAttributeTracker } from '@ts/app-body-attribute-utils';

const uniqueKeyLogViewerRequestDownloadLinkModal = 'log-viewer-request-download-link-modal';

@Component({
  selector: 'app-log-viewer-request-download-link',
  templateUrl: './log-viewer-request-download-link.component.html',
  styles: [],
})
export class LogViewerRequestDownloadLinkComponent extends AbstractController implements OnDestroy, OnInit {
  // 🌸

  constructor(
    logger: Logger,
    private apiService: SoracomApiService,
    private translateService: TranslateService,
  ) {
    super(logger);
  }

  @Output() close = new EventEmitter();

  @Output() downloadLinkRequestSuccess = new EventEmitter();

  alertsManager = new AlertsManager();

  private _queryTimeRange: TimeRange = defaultDownloadTimeRange;

  get queryTimeRange() {
    return this._queryTimeRange;
  }

  set queryTimeRange(newValue) {
    this._queryTimeRange = newValue;
  }

  ngOnInit(): void {
    super.ngOnInit();
    //Need to add app-state attribute to the body for sds compatibility
    ModalAppStateAttributeTracker.addModalAppState(uniqueKeyLogViewerRequestDownloadLinkModal);
  }

  ngOnDestroy(): void {
    ModalAppStateAttributeTracker.removeModalAppState(uniqueKeyLogViewerRequestDownloadLinkModal);
  }

  // @ts-expect-error (legacy code incremental fix)
  dateRange: DateRange;

  onQueryTimeRangeChange(newTimeRange: TimeRange) {
    const newDateRange = DateRange.fromTimeRange(newTimeRange);
    this.debug(`onQueryTimeRangeChange: new date range:`, newDateRange);
    this.queryTimeRange = newTimeRange;
  }

  relativeTimeRangeOptions = LogViewerDateTimeRanges.defaultRelativeTimeRangeOptions;

  async requestDownloadLink() {
    const rightNow = new Date().getTime();
    const dateRange = DateRange.fromTimeRange(this.queryTimeRange);
    const from_epoch_ms = dateRange.from?.getTime();

    // If end datetime not specified, it means 'until now':
    const to_epoch_ms = dateRange?.to?.getTime() ?? rightNow;

    const currentLang = this.translateService.currentLang;
    const accept_language = currentLang?.toLowerCase() === 'ja' ? 'JA' : 'EN';

    try {
      if (!from_epoch_ms) {
        throw new Error('invalid from_epoch_ms');
      }
      if (!to_epoch_ms) {
        throw new Error('invalid to_epoch_ms');
      }

      const response = await this.apiService.getAuditLogsDownloadLink(from_epoch_ms, to_epoch_ms, accept_language);
      // no error means it worked
      this.downloadLinkRequestSuccess.emit();
      this.close.emit();
    } catch (err) {
      const alert = Alert.danger(err);
      this.alertsManager.add(alert);
    }
  }
}
