<div class="harvest-data-viz-area">
  <aside class="harvest-data-viz-control-panel">
    <details class="ds-disclosure --simple" open>
      <summary class="ds-text --label">{{ 'data.map.data_format' | translate }}</summary>
      <app-harvest-data-map-parser-setting-control
        [parserConfig]="parserConfig"
        (parserConfigChange)="onParserConfigChange($event)"
      ></app-harvest-data-map-parser-setting-control>
    </details>
  </aside>

  <app-marker-map
    class="harvest-data-viz-chart"
    [tooltipHtml]="mapTooltipHtml"
    [markers]="mapData"
    (onClick)="onClick($event)"
  ></app-marker-map>

  <app-harvest-data-chart-preview-panel [data]="previewData" class="harvest-data-viz-preview-panel">
  </app-harvest-data-chart-preview-panel>
</div>
