import { LegacyAny } from '@soracom/shared/core';

import * as angular from 'angular';
import { AlertsService, AlertsServiceInstance } from '../components/alerts.service';
import { BaseController } from '../components/base_controller';
import { groupsService, AirType } from '@soracom/shared/soracom-services-ui/groups-ui';
import { Group } from '@soracom/shared/group';
import { InjectList } from '../core/injectable';
import { template } from './group_details.component.html';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';
import { GroupDeleteModalComponent } from '../../../src/app/soracom-groups/delete_group_modal/group_delete.modal.component';
import { CredAddModalComponent } from '../../../src/app/ng-rewrites/legacy-cred-add/cred_add.modal.component';
import { DateTimeFormatType, formatDateTime } from '@soracom/shared/util-common';

export class GroupDetailsComponent implements ng.IComponentOptions {
  bindings = {
    airType: '<',
    groupId: '<',
  };
  controller = GroupDetailsController;
  template: any = template;
}

export class GroupDetailsController extends BaseController {
  // @ts-expect-error (legacy code incremental fix)
  private translate: (key: string) => string;
  loadingSubscribers = false;
  // @ts-expect-error (legacy code incremental fix)
  groupId: string;
  // @ts-expect-error (legacy code incremental fix)
  original: Group;
  // @ts-expect-error (legacy code incremental fix)
  editing: Group;
  isCellular = false;
  isLorawan = false;
  isSigfox = false;
  isDevice = false;
  isSatellite = false;
  status: any = {};
  serviceName = 'GroupsService';

  subscribers = {};
  subscribersCount = 0;
  nameEditCtrl: any;
  alertsService: AlertsServiceInstance;
  subscribersTableCtrl: any;
  subscribersTableColumnOptions: any;
  // @ts-expect-error (legacy code incremental fix)
  subscribersPerPage: number;
  // @ts-expect-error (legacy code incremental fix)
  subscribersPerPageOptions: number[];
  soracomBeamConfigCount?: number;
  pagination: { keys: { [idx: number]: any }; currentPage: number; reachedLast: boolean } = {
    keys: {},
    currentPage: 0,
    reachedLast: false,
  };
  // @ts-expect-error (legacy code incremental fix)
  airType: AirType;
  // @ts-expect-error (legacy code incremental fix)
  unifiedResponseFormats: any[];
  // @ts-expect-error (legacy code incremental fix)
  path: string;
  createdAt?: number;
  lastModifiedAt?: number;

  static $inject: InjectList = ['$location', '$log', 'UiDsModalService', '$q', '$scope', '$window', 'AlertsService'];

  constructor(
    private $location: ng.ILocationService,
    private $log: ng.ILogService,
    private uiDsModal: UiDsModalService,
    private $q: ng.IQService,
    private $scope: ng.IScope,
    private $window: LegacyAny,
    alertsServiceManager: AlertsService,
  ) {
    super($log);
    this.alertsService = alertsServiceManager.generate();
  }

  $onInit(): void {
    this.isCellular = this.airType === AirType.cellular;
    this.isLorawan = this.airType === AirType.lorawan;
    this.isSigfox = this.airType === AirType.sigfox;
    this.isDevice = this.airType === AirType.device;
    this.isSatellite = this.airType === AirType.satellite;

    if (this.isCellular) {
      this.status = {
        air: { isOpen: false },
        beam: { isOpen: false },
        endorse: { isOpen: false },
        funk: { isOpen: false },
        funnel: { isOpen: false },
        harvest: { isOpen: false },
        harvestFiles: { isOpen: false },
        krypton: { isOpen: false },
        orbit: { isOpen: false },
        peh: { isOpen: false },
        tags: { isOpen: true },
        unified: { isOpen: false },
      };
      this.path = 'groups';
    } else if (this.isLorawan) {
      this.status = {
        air: { isOpen: true },
        beam: { isOpen: true },
        endorse: { isOpen: false },
        funk: { isOpen: true },
        funnel: { isOpen: true },
        harvest: { isOpen: true },
        harvestFiles: { isOpen: false, hidden: true },
        krypton: { isOpen: false, hidden: true },
        orbit: { isOpen: false, hidden: true },
        peh: { isOpen: false },
        tags: { isOpen: true },
        unified: { isOpen: false },
      };
      this.path = 'lora_device_groups';
    } else if (this.isSigfox) {
      this.status = {
        air: { isOpen: true },
        beam: { isOpen: true },
        endorse: { isOpen: false },
        funk: { isOpen: true },
        funnel: { isOpen: true },
        harvest: { isOpen: true },
        harvestFiles: { isOpen: false, hidden: true },
        krypton: { isOpen: false, hidden: true },
        orbit: { isOpen: false, hidden: true },
        peh: { isOpen: false },
        tags: { isOpen: true },
        unified: { isOpen: false },
      };
      this.path = 'sigfox_device_groups';
    } else if (this.isDevice) {
      this.status = {
        air: { isOpen: false, hidden: true },
        beam: { isOpen: true },
        endorse: { isOpen: false },
        funk: { isOpen: true },
        funnel: { isOpen: true },
        harvest: { isOpen: true },
        harvestFiles: { isOpen: false, hidden: true },
        krypton: { isOpen: false, hidden: true },
        orbit: { isOpen: false, hidden: true },
        peh: { isOpen: false },
        tags: { isOpen: true },
        unified: { isOpen: false },
      };
      this.path = 'inventory_device_groups';
    } else if (this.isSatellite) {
      this.status = {
        air: { isOpen: true },
        beam: { isOpen: true },
        endorse: { isOpen: false },
        funk: { isOpen: false },
        funnel: { isOpen: false },
        harvest: { isOpen: true },
        harvestFiles: { isOpen: false, hidden: true },
        krypton: { isOpen: false, hidden: true },
        orbit: { isOpen: false, hidden: true },
        peh: { isOpen: false },
        tags: { isOpen: true },
        unified: { isOpen: false },
      };
      this.path = 'satellite_device_groups';
    } else {
      this.$log.error('Unknown Group type.');
    }
    this.nameEditCtrl = this.createNameEditCtrl();
  }

  $onChanges(changesObj: any) {
    if (changesObj && changesObj.groupId) {
      this.groupId = changesObj.groupId.currentValue;
      this.$log.debug('$onChanges', changesObj);
      this.$window.scrollTo(0, 0);
      this.updateGroup();
    }
  }

  updateGroup(): Promise<void> {
    if (this.groupId) {
      return groupsService.get(this.groupId).then((group) => {
        this.$log.debug('Apply group change', group);
        this.editing = group;
        this.original = angular.copy(group);
        this.soracomBeamConfigCount = this.getSoracomBeamConfigCount();
        return;
      });
    } else {
      return Promise.resolve();
    }
  }

  /**
   * This is a hotfix for https://app.shortcut.com/soracom/story/102094/ui-glitch-in-updated-group-details-ui. AFAICT the things the template is trying to show never exist. If there is actually some scenario where we should be showing the last modified date, then we should update this, but for now I am hardcoding `false` to fix the bug in production.
   */
  shouldShowGroupLastModified = false;

  unsavedChangesCheck() {
    // We don't actually check for unsaved changes here, but I leave this method definition and comment here for
    // future developers. This *seemed* like the place to check for unsaved changes, and perhaps for some controllers
    // it would be.
    //
    // However, here it doesn't work. I intended to do something like this:
    //
    //     if (_.isEqual(this.original, this.editing)) { ...
    //
    // This simple check worked in my proof-of-concept hack, but that was lower down in the view hierarchy. That worked
    // in the GroupAirParamsDirective (just checking the view model and comparing it to this.original). And it works in
    // the other sibling directives for editing Beam settings, Funnel settings etc.
    //
    // But it does not work here, and actually, even a complex inspection of the this.editing object doesn't work, because
    // at the time of the navigation event (window close (onbeforeunload) or the Angular JS event (scope.$on('$locationChangeStart')
    // the changed state is NOT available to GroupDetailsController. I mean, at least not through data binding in its
    // this.editing structure
    //
    // Even though I edited every editor in the group settings screen, that structure still looks like this at the time unsavedChanges() is called:
    //
    // "{"operatorId":"OP0022007544","groupId":"93190a63-cece-4e0c-808e-de3e04f85b13","createdAt":1469162013593,"lastModifiedAt":1469162013593,"configuration":{"SoracomAir":{"dnsServers":[]}},"tags":{"name":"testgroup"},"createdTime":1469162013593,"lastModifiedTime":1469162013593}"
    //
    // So, currently anyway, pending edits are not exposed to GroupDetailsController before they are committed.
    //
    // I am very leery of making architectural changes to the app at this stage, since I just started working on it,
    // and am new to Angular. So my thinking here is, if the app currently only exposes pending edits at the level of
    // the directive that controls the UI for those edits, then perhaps we should just have each editor directive and/or
    // controller register to intercept navigation events to protect unsaved changes, even though instinctively I feel
    // like maybe that belongs at a higher level in the UI controller hierarchy.
    //
    // This does work, and it is what we went with. (Mason 2016-07-29)

    return null;
  }

  createNameEditCtrl() {
    function NameEditCtrl() {}

    NameEditCtrl.prototype.save = (key: LegacyAny, value: LegacyAny) => {
      return groupsService
        .updateTags(this.editing.groupId, [
          {
            tagName: key,
            tagValue: value,
          },
        ])
        .then(() => {
          return this.$q.resolve();
        })
        .catch((e: LegacyAny) => {
          this.alertsService.showAlert('danger', { translationId: 'group_details.alert.name' });
          return this.$q.reject(e);
        });
    };

    NameEditCtrl.prototype.remove = (_key: LegacyAny) => {
      return this.$q.resolve();
    };

    // @ts-expect-error (legacy code incremental fix)
    return new NameEditCtrl();
  }
  setSoracomBeamConfigCount($event: number) {
    this.soracomBeamConfigCount = $event;
  }
  getSoracomBeamConfigCount() {
    if (this.editing === undefined || this.editing === null) {
      return 0;
    }
    if (this.editing.configuration === undefined || this.editing.configuration === null) {
      return 0;
    }

    const p = this.editing.configuration.SoracomBeam;
    if (!!p) {
      return Object.keys(p).length;
    } else {
      return 0;
    }
  }

  deleteThisGroup() {
    const okAction = () => {
      this.$location.url(`/${this.path}/`);
    };
    this.uiDsModal
      .openAndWaitForResult(GroupDeleteModalComponent, {
        title: 'group_delete.header',
        data: {
          resolve: {
            params: () => {
              return {
                group: this.editing,
              };
            },
          },
        },
      })
      .then((res) => {
        if (res?.success) {
          okAction();
        }
      });
  }

  duplicateThisGroup() {
    groupsService
      .copy(this.original, `${this.original.name} copy`)
      .then((group) => {
        this.$location.url(`/${this.path}/${group.id}`);
      })
      .catch((e: LegacyAny) => {
        this.alertsService.showError(e);
      });
  }

  /**
   * This method lets a child component ask this component to show the 'add credentials` modal on their behalf. A hack needed for Angular components that need to do this. See FunkConfigEditorComponent for example.
   */
  addNewCredentialsForAngularChildComponent(
    resolveObj: LegacyAny,
    completionHandler: LegacyAny,
    cancelHandler: LegacyAny,
  ) {
    this.uiDsModal
      .openAndWaitForResult(CredAddModalComponent, {
        title: 'security.credentials.modals.register.header',
        data: {
          resolve: resolveObj,
        },
      })
      .then((res) => {
        if (res) {
          completionHandler(res);
        } else {
          cancelHandler();
        }
      });
  }

  formatDateTime(unixMsec: number, format: DateTimeFormatType) {
    const s = formatDateTime(unixMsec, format, { fallback: undefined });
    return s;
  }
}
