export const template = `<div class="ds-cols --end">
  <div class="ds-bar">
    <span
      data-testid="pagination-items-per-page"
      ng-if="$ctrl.showPerPage()"
      for="items-per-page-select"
      translate="sc_pagination.items_per_page"
    ></span>
    <div class="ds-field">
      <select
        data-testid="pagination-items-per-page-select"
        class="ds-select"
        ng-if="$ctrl.showPerPage()"
        ng-model="$ctrl.paginator.perPage"
        ng-options="itemCount for itemCount in $ctrl.itemsPerPageOptions"
        ng-change="$ctrl.updateItemsPerPage()"
      ></select>
    </div>
    <span
      data-testid="pagination-table-data-count"
      ng-show="$ctrl.isVisibleItemCountAndPosition()"
      translate="sc_pagination.items_count_and_position"
      translate-values="{{$ctrl.translationDataForItemsCountAndPosition()}}"
    ></span>
    <span
      data-testid="pagination-table-selection-count"
      ng-show="$ctrl.isVisibleItemCountAndPositionAndSelection()"
      translate="sc_pagination.items_count_and_position_and_selection"
      translate-values="{{$ctrl.translationDataForItemsCountAndPosition()}}"
    ></span>
  </div>
  <div class="ds-bar">
    <button
      data-testid="pagination-pager-prev-button"
      class="ds-button --plain --hide-label --icon-arrow-left --bottom-left"
      ng-if="$ctrl.showPager()"
      ng-class="{ disabled: !$ctrl.hasPrev() }"
      ng-click="$ctrl.prev()"
      ng-disabled="!$ctrl.hasPrev() || $ctrl.isLoading"
    >
      <span translate="sc_pagination.prev"></span>
    </button>
    <button
      data-testid="pagination-pager-next-button"
      class="ds-button --plain --hide-label --icon-arrow-right --bottom-left"
      ng-if="$ctrl.showPager()"
      ng-class="{ disabled: !$ctrl.hasNext() }"
      ng-click="$ctrl.next()"
      ng-disabled="!$ctrl.hasNext() || $ctrl.isLoading"
    >
      <span translate="sc_pagination.next"></span>
    </button>
    <button
      data-testid="pagination-reload-button"
      class="ds-button --plain --hide-label --icon-refresh-cw --bottom-left"
      ng-disabled="!$ctrl.reloadable() || $ctrl.isLoading"
      ng-click="$ctrl.reload()"
    >
      <span translate="sc_pagination.reload"></span>
    </button>
    <button
      data-testid="ng1-table-settings-button"
      ng-if="$ctrl.shouldShowSettingsButton()"
      ng-click="$ctrl.executeParentSettingsFunction()"
      type="button"
      class="ds-button --plain --icon-settings --hide-label --bottom-left"
    >
      <span>Table Settings</span>
    </button>
  </div>
</div>`;
