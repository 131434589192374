import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SoracomCommonModule } from '../soracom-common/soracom-common.module';
import { UiDatetimeModule } from '@soracom/shared-ng/ui-common';
import { SoracomUiModule } from '../soracom-ui/soracom-ui.module';
import { LogViewerControlsComponent } from './log-viewer-controls/log-viewer-controls.component';
import { LogViewerHeaderComponent } from './log-viewer-header/log-viewer-header.component';
import { LogViewerPaginatorComponent } from './log-viewer-paginator/log-viewer-paginator.component';
import { LogViewerQueryEditorComponent } from './log-viewer-query-editor/log-viewer-query-editor.component';
import { LogViewerRecordDetailInspectorComponent } from './log-viewer-record-detail-inspector/log-viewer-record-detail-inspector.component';
import { LogViewerRequestDownloadLinkComponent } from './log-viewer-request-download-link/log-viewer-request-download-link.component';
import { LogViewerSubscriptionModalComponent } from './log-viewer-subscription-modal/log-viewer-subscription-modal.component';
import { LogViewerSubscriptionStatusComponent } from './log-viewer-subscription-status/log-viewer-subscription-status.component';
import { LogViewerTableComponent } from './log-viewer-table/log-viewer-table.component';
import { LogViewerComponent } from './log-viewer/log-viewer.component';

@NgModule({
  declarations: [
    LogViewerComponent,
    LogViewerHeaderComponent,
    LogViewerControlsComponent,
    LogViewerQueryEditorComponent,
    LogViewerPaginatorComponent,
    LogViewerTableComponent,
    LogViewerRecordDetailInspectorComponent,
    LogViewerSubscriptionStatusComponent,
    LogViewerSubscriptionModalComponent,
    LogViewerRequestDownloadLinkComponent,
  ],
  imports: [CommonModule, FormsModule, UiDatetimeModule, SoracomUiModule, SoracomCommonModule],
  exports: [LogViewerComponent],
})
export class SoracomLogsModule {}
