import { isRelativeTimeRange, TimeRange } from '@soracom/shared-ng/ui-common';

/**
 * `DateRange` represents a range of time. If `from` is `undefined`, that means "since the beginning of time" and if `to` is undefined that means "until the end of time".
 */
export class DateRange {
  /**
   * Construct a date range. Either `from` or `to` may be unspecified, meaning that end of the range is not defined.
   */
  constructor(
    public from?: Date,
    public to?: Date,
  ) {}

  /**
   * Convenience method to convert ` `TimeRange` to a `DateRange`.
   */
  static fromTimeRange(timeRange: TimeRange) {
    const { from, to } = isRelativeTimeRange(timeRange) ? timeRange.fn() : { ...timeRange };

    const result = new this();

    if (from != null) {
      result.from = new Date(from);
    }
    if (to != null) {
      result.to = new Date(to);
    }
    return result;
  }
}
