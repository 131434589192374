import { convert } from 'html-to-text';
import { ConversationMessageResponse } from './ApiTypes';

import { ChartConfig, PlotlyUtils } from './PlotlyUtils';

import { ConversationType } from './ConversationType';
import { MarkdownRenderer } from './MarkdownRenderer';
import { ChatdbAnalysisCsvWithCsvContent } from './ChatdbResponse';

export type MessageWrapperOptions = {
  readonly type: ConversationType;
};

/**
 A rich wrapper for the `ConversationMessageResponse` API structure.

 This wrapper computes cachable values derived from the API structure, such as rendering Markdown content into HTML to avoid re-rendering.
 */
export class MessageWrapper {
  //
  // @masonmark 2024-06-26: this is INTENTIONALLY BAD CODE because, due to scheduling rush / "war mode" stuff I had to do development on 2 simultaneous tracks and it is really not optimal. So I am getting the code in in LINEAR order, and then I am going to clean it up.
  // Here's the CHATDB stuff all at the top for now:
  csvAnalysis: ChatdbAnalysisCsvWithCsvContent | undefined;

  /**
   The HTML render should be cached so we don't render over and over as DOM elements are added/removed due to normal Angular component rendering.
   */
  renderHtmlForChatdb(
    markdownContent: string,
    as: 'user' | 'assistant',
  ): string {
    if (this.isChatdb && as === 'assistant') {
      // if (true as any) {
      //   console.log('this.csvAnalysis', this.csvAnalysis, this);
      //   return 'hello';
      // }
      if (!this.csvAnalysis) {
        return '';
      }
      const sql = this.csvAnalysis.sql;
      return `<h3>SQL query info</h3><pre>${sql}</pre>`;
    } else {
      // console.log('renderHtml', markdownContent);
      const prefixedContent =
        as === 'user' ? `${markdownContent}` : `**🤖 Bot:** ${markdownContent}`;
      const html = MessageWrapper.markdownRenderer.parse(prefixedContent);
      if (typeof html !== 'string') {
        return 'HTML could not be rendered synchronously';
      }
      return html;
    }
  }

  get plotlyDivId(): string {
    return this.hasPlotly ? `plotly-${this.messageId}` : '';
  }

  generatePlotlyDataAndLayout() {
    let anal = this.csvAnalysis;
    if (!anal) {
      return;
    }
    if ((anal as any).answer) {
      anal = (anal as any).answer as ChatdbAnalysisCsvWithCsvContent;
    }

    const csvData = anal.csvContent;
    const config: ChartConfig = JSON.parse(anal.figmodel);

    try {
      const { data, layout } = PlotlyUtils.generatePlotData(csvData, config);

      this._plotlyData = data;
      this._plotlyLayout = layout;

      return { data, layout };
    } catch (error) {
      console.error('Error generating Plotly data:', error);
      return undefined;
    }
  }

  private _plotlyData: any;

  get plotlyData(): any {
    if (!this._plotlyData) {
      this.generatePlotlyDataAndLayout();
    }
    return this._plotlyData;
  }

  private _plotlyLayout: any;

  get plotlyLayout(): any {
    if (!this._plotlyLayout) {
      this.generatePlotlyDataAndLayout();
    }
    return this._plotlyLayout;
  }

  get hasPlotly() {
    return this.conversationType === 'chatdb' && this.csvAnalysis;
    // FIXME
  }

  private _tableCsv: { headers: string[]; rows: string[][] } | undefined;

  get tableCsv() {
    if (this._tableCsv) {
      return this._tableCsv;
    }
    if (!(this.isChatdb && this.csvAnalysis?.csvContent)) {
      return undefined;
    }

    // FIXME: naive
    const rows = this.csvAnalysis.csvContent.split('\n');
    if (rows.length < 2) {
      return undefined;
    }

    this._tableCsv = {
      headers: rows.shift()?.split(',') ?? [],
      rows: rows.map((row) => row.split(',')),
    };

    // Remove last empty row
    this._tableCsv.rows.pop();

    if (this._tableCsv.headers[0].trim() === '') {
      this._tableCsv.headers.shift();
      this._tableCsv.rows.forEach((row) => row.shift());
    }

    return this._tableCsv;
  }

  // END (one part of) BAD CODE

  // static markdownRenderer = new Marked({ gfm: true, async: false });
  static markdownRenderer = new MarkdownRenderer().renderer;

  get messageId() {
    return this.message.messageId;
  }

  readonly conversationType: ConversationType;

  get isChatdb() {
    return this.conversationType === 'chatdb';
  }

  get isSupportBot() {
    return this.conversationType === 'support-bot';
  }

  constructor(
    readonly message: ConversationMessageResponse,
    options: MessageWrapperOptions,
  ) {
    this.conversationType = options.type;

    if (this.conversationType === 'chatdb') {
      if (message.answerStatus === 'completed' && message.answer) {
        const json = JSON.parse(
          message.answer,
        ) as ChatdbAnalysisCsvWithCsvContent;
        this.csvAnalysis = json;
      }
    }
  }

  get isFailedOrAborted() {
    return (
      this.message.answerStatus === 'failed' ||
      this.message.answerStatus === 'aborted'
    );
  }

  isConversationOpener = false;

  private _referenceDocLinks?: Array<{
    title: string;
    url: string;
    trackBy: string;
  }>;

  get referenceDocLinks(): Array<{
    title: string;
    url: string;
    trackBy: string;
  }> {
    if (this._referenceDocLinks) {
      return this._referenceDocLinks;
    }

    // FIXME: API BUG: the type is object but reality data is string that needs parsing

    try {
      // Use || because empty string is a possibility here
      const rawDocLinks = this.message.answerReferenceDocuments || '[]';
      const parsedJson = JSON.parse((rawDocLinks as string) ?? '[]');

      const result: Array<{ title: string; url: string; trackBy: string }> = [];

      let i = 0;
      if (parsedJson && parsedJson.length > 0) {
        for (const rawDocLink of parsedJson) {
          result.push({
            title: rawDocLink.title,
            url: rawDocLink.url,
            trackBy: `${i}-${rawDocLink.url}-${rawDocLink.title}-${this.messageId}`,
          });
          i++;
          if (i > 5) {
            break;
          }
        }
      }

      this._referenceDocLinks = result;
    } catch (error) {
      // Ignore error; treat it as "no doc links"
      this._referenceDocLinks = [];
    }
    return this._referenceDocLinks;
  }

  private _complimentaryDocsLinks?: Array<{
    title: string;
    url: string;
    trackBy: string;
  }>;
  get complimentaryDocLinks(): Array<{
    title: string;
    url: string;
    trackBy: string;
  }> {
    if (this._complimentaryDocsLinks) {
      return this._complimentaryDocsLinks;
    }

    // FIXME: API BUG: the type is object but reality data is string that needs parsing

    try {
      // Use || because empty string is a possibility here
      const rawDocLinks = this.message.answerComplimentaryDocuments || '[]';
      const parsedJson = JSON.parse((rawDocLinks as string) ?? '[]');

      const result: Array<{ title: string; url: string; trackBy: string }> = [];

      let i = 0;
      if (parsedJson && parsedJson.length > 0) {
        for (const rawDocLink of parsedJson) {
          result.push({
            title: rawDocLink.title,
            url: rawDocLink.url,
            trackBy: `${i}-${rawDocLink.url}-${rawDocLink.title}-${this.messageId}`,
          });
          i++;
          if (i > 5) {
            break;
          }
        }
      }

      this._complimentaryDocsLinks = result;
    } catch (error) {
      // Ignore error; treat it as "no index links"
      this._complimentaryDocsLinks = [];
    }
    return this._complimentaryDocsLinks;
  }

  renderHtml(markdownContent: string, as: 'user' | 'assistant'): string {
    // HACK; FIXME:
    if (this.isChatdb && as === 'assistant') {
      return this.renderHtmlForChatdb(markdownContent, as);
    }

    // console.log('renderHtml', markdownContent);
    const prefixedContent =
      as === 'user' ? `${markdownContent}` : `${markdownContent}`;
    const html = MessageWrapper.markdownRenderer.parse(prefixedContent);

    if (typeof html !== 'string') {
      return 'HTML could not be rendered synchronously';
    }
    return html;
  }

  renderPlaintext(markdownContent: string) {
    const html = this.renderHtml(markdownContent, 'user');
    const text = convert(html);
    return text;
  }

  private _renderedAnswer: string | undefined;

  get renderedAnswer(): string | undefined {
    if (this._renderedAnswer) {
      return this._renderedAnswer;
    }
    if (this.message.answerStatus === 'completed' && this.message.answer) {
      this._renderedAnswer = this.renderHtml(this.message.answer, 'assistant');
    }
    return this._renderedAnswer;
  }

  get renderedAnswerPreview(): string | undefined {
    if (this.message.answerStatus === 'completed' && this.message.answer) {
      return this.renderPlaintext(this.message.answer);
    }
    return undefined;
  }

  private _renderedQuestion: string | undefined;

  get renderedQuestion(): string | undefined {
    if (this._renderedQuestion) {
      return this._renderedQuestion;
    }
    if (this.message.question) {
      this._renderedQuestion = this.renderHtml(this.message.question, 'user');
    }
    return this._renderedQuestion;
  }

  private _renderedError: string | undefined;

  getRenderedError(errText: {
    heading: string;
    details: string;
  }): string | undefined {
    if (this._renderedError) {
      return this._renderedError;
    }

    const markdown = `###### ${errText.heading}\n\n${errText.details}`;
    this._renderedError = this.renderHtml(markdown, 'assistant');

    return this._renderedError;
  }
}
