import {
  DEFAULT_RELATIVE_TIMERANGES,
  LAST_15_MINUTES,
  LAST_5_MINUTES,
  LAST_7_DAYS,
} from '@soracom/shared-ng/ui-common';

export const defaultDownloadTimeRange = LAST_7_DAYS;

export class LogViewerDateTimeRanges {
  static get defaultRelativeTimeRangeOptions() {
    const result = [...DEFAULT_RELATIVE_TIMERANGES];
    result.unshift(LAST_5_MINUTES, LAST_15_MINUTES);
    return result;
  }
}
