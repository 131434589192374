import { LegacyAny } from '@soracom/shared/core';

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HarvestContract } from '../../../../app/shared/core/harvest_contract';
import { HarvestDataService } from '../../../../app/shared/harvest_data/harvest_data.service';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { DatetimeRangeService } from '@soracom/shared-ng/ui-common';
import { TimeRange } from '@soracom/shared-ng/ui-common';
import { UiButtonBar } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiButton } from '@soracom/shared-ng/soracom-ui-legacy';
import { DsModalContentBase } from '@soracom/shared-ng/ui-ds-modal';
import { generateSearchQueries } from '../harvest-data.store';
import { HarvestResource, TIME_RANGE_OPTIONS } from '../harvest-data.type';
import { API_ERROR, API_REQUEST_LIMIT, REACH_SIZE_LIMIT, USER_CANCEL } from './harvest-data-download-error-code';
import {
  HarvestDataDownloadAction,
  HarvestDataDownloadResult,
  HarvestDataDownloadType,
} from './harvest-data-download.action';

export interface HarvestDataDownloadModalInput {
  resources: HarvestResource[];
  timeRange: TimeRange;
}

export type HarvestDataDownloadModalState = 'standBy' | 'downloading' | 'interrupted' | 'done';

@Component({
  templateUrl: './harvest-data-download-modal.component.html',
  providers: [HarvestDataDownloadAction],
})
export class HarvestDataDownloadModalComponent
  extends DsModalContentBase<HarvestDataDownloadModalInput, null>
  implements OnInit
{
  className = 'HarvestDataDownloadModalComponent';
  // @ts-expect-error (legacy code incremental fix)
  downloadFormat: HarvestDataDownloadType;
  // @ts-expect-error (legacy code incremental fix)
  targetResource: HarvestResource;
  // @ts-expect-error (legacy code incremental fix)
  resourceId: string;
  // @ts-expect-error (legacy code incremental fix)
  downloadBtn: UiButton;
  // @ts-expect-error (legacy code incremental fix)
  cancelBtn: UiButton;
  // @ts-expect-error (legacy code incremental fix)
  resources: HarvestResource[];
  // @ts-expect-error (legacy code incremental fix)
  timeRange: TimeRange;
  // @ts-expect-error (legacy code incremental fix)
  state: HarvestDataDownloadModalState;
  contract: HarvestContract | undefined;
  alertManager = new AlertsManager();
  footerBar = new UiButtonBar();
  // @ts-expect-error (legacy code incremental fix)
  isExtended: boolean;

  data = this.getInput();

  formats = [
    {
      i18nKey: 'HarvestData.actions.download_content',
      identifier: 'content',
    },
    {
      i18nKey: 'HarvestData.actions.download_chart_data',
      identifier: 'chart_data',
    },
    {
      i18nKey: 'HarvestData.actions.download_chart_data_csv',
      identifier: 'chart_data_csv',
    },
  ];

  constructor(
    private cdRef: ChangeDetectorRef,
    private datetimeRange: DatetimeRangeService,
    private action: HarvestDataDownloadAction,
    private harvestDataSvc: HarvestDataService,
    private t: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    // init buttons
    this.downloadBtn = new UiButton();
    this.downloadBtn.titleId_ƒ = () =>
      this.state === 'interrupted' ? 'HarvestData.download.btn_resume' : 'HarvestData.download.btn_download';
    this.downloadBtn.onClick = (e) => this.download();
    this.downloadBtn.buttonLoadingState_ƒ = () => {
      if (this.state === 'downloading') {
        return 'loading';
      }
      return undefined;
    };
    this.downloadBtn.buttonStyle = 'primary';

    this.cancelBtn = new UiButton();
    this.cancelBtn.titleId = 'HarvestData.download.btn_cancel';
    this.cancelBtn.onClick = (e) => this.cancelDownload();

    this.resources = this.data.resources;
    this.timeRange = this.data.timeRange;

    // default value
    this.downloadFormat = 'content';
    this.targetResource = this.resources[0];
    this.state = 'standBy';

    this.footerBar = UiButtonBar.configure((bar) => {
      bar.rightButtons = [
        UiButton.configure((button) => {
          button.titleId = 'HarvestData.download.close';
          button.onClick = () => this.close();
          button.isDisabled_ƒ = this.cancelDisabled;
        }),
      ];
    });

    this.contract = this.harvestDataSvc.getContract();
    this.isExtended = this.contract.equals(HarvestContract.extended);
    this.modalRef.disableEscKeyClose = this.cancelDisabled;
  }

  get progress() {
    return this.action.downloadProgress;
  }

  setTimeRange = (timeRange: TimeRange) => {
    this.timeRange = timeRange;
  };

  download() {
    this.alertManager.clear();
    let promise;
    if (this.state === 'interrupted') {
      promise = this.action.resumeDownload();
    } else {
      const downloadQueries = generateSearchQueries([this.targetResource], this.timeRange, 1000);
      promise = this.action.startDownload(
        downloadQueries[0],
        this.downloadFormat as HarvestDataDownloadType,
        downloadQueries[0].resourceId
      );
    }
    this.state = 'downloading';
    promise
      .then((res: HarvestDataDownloadResult) => {
        if (!res.err) {
          this.state = 'done';
          this.alertManager.add(Alert.info('HarvestData.download.success_download'));
        } else {
          this.state = 'interrupted';
          if (res.code === API_REQUEST_LIMIT) {
            this.alertManager.add(Alert.danger('HarvestData.download.err_api_request_limit'));
          }
          if (res.code === API_ERROR) {
            this.alertManager.add(Alert.danger('HarvestData.download.err_api_error'));
          }
          if (res.code === REACH_SIZE_LIMIT) {
            this.alertManager.add(Alert.info('HarvestData.download.err_reach_size'));
          }
          if (res.code === USER_CANCEL) {
            this.state = 'standBy';
          }
        }
      })
      .catch((err: LegacyAny) => {
        this.state = 'interrupted';
        this.alertManager.add(Alert.danger('HarvestData.download.err_others'));
      });
  }

  cancelDownload() {
    this.action.cancelDownload();
  }

  isDownloading(): boolean {
    return this.state === 'downloading' || this.state === 'interrupted';
  }

  get timeRangeOptions() {
    return TIME_RANGE_OPTIONS;
  }

  private cancelDisabled = () => this.state === 'downloading';
}
