import { LegacyAny } from '@soracom/shared/core';

import { AirType, groupsService } from '@soracom/shared/soracom-services-ui/groups-ui';
import { Group } from '@soracom/shared/group';
import { AlertsService, AlertsServiceInstance } from '../components/alerts.service';
import { BaseController } from '../components/base_controller';
import { InjectList } from '../core/injectable';
import { template } from './groups.component.html';

import { GroupMap } from './groups_cache';

import { BreadcrumbService } from '@soracom/shared-ng/routing';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';
import { BreadcrumbForDashboard } from '@soracom/shared/routing';
import { GroupAddModalComponent } from '../../../src/app/soracom-groups/add_group_modal/group_add.modal.component';

export class GroupsComponent implements ng.IComponentOptions {
  bindings = {
    airType: '@',
  };
  controller = GroupsController;
  template: any = template;
}

export class GroupsController extends BaseController {
  static $inject: InjectList = [
    '$log',
    '$routeParams',
    '$route',
    '$scope',
    'AlertsService',
    'BreadcrumbService',
    '$translate',
    'UiDsModalService',
  ];

  airType?: AirType;
  private defaultAirType = AirType.cellular;
  // @ts-expect-error (legacy code incremental fix)
  isCellular: boolean;
  // @ts-expect-error (legacy code incremental fix)
  isLorawan: boolean;
  // @ts-expect-error (legacy code incremental fix)
  isSigfox: boolean;
  // @ts-expect-error (legacy code incremental fix)
  isDevice: boolean;
  // @ts-expect-error (legacy code incremental fix)
  isSatellite: boolean;
  // @ts-expect-error (legacy code incremental fix)
  groups: GroupMap;
  // @ts-expect-error (legacy code incremental fix)
  activeGroup: Group;
  // @ts-expect-error (legacy code incremental fix)
  coverageType: string;
  // @ts-expect-error (legacy code incremental fix)
  path: string;
  // @ts-expect-error (legacy code incremental fix)
  groupNotFoundId: string;

  alertsService: AlertsServiceInstance;

  linkClass: string[] = [];

  constructor(
    private $log: ng.ILogService,
    private $routeParams: any,
    private $route: any,
    private $scope: ng.IScope,
    alertsServiceGenerator: AlertsService,
    private breadcrumbService: BreadcrumbService,
    private $translate: any,
    private uiDsModalService: UiDsModalService
  ) {
    super($log);
    this.alertsService = alertsServiceGenerator.generate();
  }

  $onInit() {
    if (!this.airType) {
      this.airType = this.defaultAirType;
    }
    this.isCellular = this.airType === AirType.cellular;
    this.isLorawan = this.airType === AirType.lorawan;
    this.isSigfox = this.airType === AirType.sigfox;
    this.isDevice = this.airType === AirType.device;
    this.isSatellite = this.airType === AirType.satellite;
    this.linkClass = [];
    if (this.isCellular) {
      this.path = 'groups';
      this.linkClass.push('x-link-to-sim-device-groups');
    } else if (this.isLorawan) {
      this.path = 'lora_device_groups';
      this.linkClass.push('x-link-to-lora-device-groups');
    } else if (this.isSigfox) {
      this.path = 'sigfox_device_groups';
      this.linkClass.push('x-link-to-sigfox-device-groups');
    } else if (this.isDevice) {
      this.path = 'inventory_device_groups';
      this.linkClass.push('x-link-to-device-groups');
    } else if (this.isSatellite) {
      this.path = 'satellite_device_groups';
      this.linkClass.push('x-link-to-satellite-device-groups');
    } else {
      this.$log.error('Unknown Group type.');
    }

    if (this.$routeParams.coverage_type) {
      this.coverageType = this.$routeParams.coverage_type;
    }

    groupsService.afterGroupUpdate$.subscribe(({ id: groupId }) => {
      if (groupId) {
        groupsService.refreshCacheById(groupId);
        groupsService.getCachedAll().then((groups) => {
          this.groups = Object.fromEntries(groups.entries());
        });
      } else {
        this.updateGroups();
      }
    });

    this.$scope.$on('$routeUpdate', (event, current, routes) => {
      if (!this.$routeParams.coverage_type || this.$routeParams.coverage_type !== this.coverageType) {
        this.coverageType = this.$routeParams.coverage_type;
        this.updateGroups();
      }

      this.$log.debug(this.$routeParams.id);
      this.$log.debug(this.activeGroup);
      if (this.$routeParams.id !== (this.activeGroup ? this.activeGroup.groupId : null)) {
        this.findGroup();
      }
    });

    this.updateGroups();

    // We need to override here to ensure the linkClass is added
    this.breadcrumbService.overrideBreadcrumbs([
      BreadcrumbForDashboard,
      { labelKey: this.getPageTitleKey(), url: `/${this.path}`, classes: this.linkClass },
    ]);
  }

  get activeGroupId(): string {
    // @ts-expect-error (legacy code incremental fix)
    return this.activeGroup ? this.activeGroup.groupId : null;
  }

  shouldShowColumn(columnName: string): boolean {
    if (columnName === 'id') {
      return this.activeGroup === null || this.activeGroup === undefined;
    } else {
      return true;
    }
  }

  updateGroups() {
    return groupsService
      .fetchAll()
      .then((groups) => {
        this.groups = Object.fromEntries(groups.entries());
        this.$log.debug(`Groups reloaded: ${Object.keys(groups).length}`);
        this.findGroup();
      })
      .catch((error) => {
        this.alertsService.showError(error);
      });
  }

  /**
   * Does nothing if groups have not yet been loaded, or if the route doesn't have a groupId in it. If those two cnditions **are** met, then this looks up the active group by ID, and sets `this.activeGroup` to the object and updates the nav breadcrumbs if needed.
   */
  findGroup() {
    const groupId = this.$routeParams.id;
    if (groupId) {
      if (!this.groups) {
        // @mason 2023-05-26: This will occur if the user navigates directly to a group page.
        // It is OK, though, because findGroup() will be called again when the groups are loaded.
        // However, we had a [bug](https://app.shortcut.com/soracom/story/87356/findgroup-throws-js-errors-in-the-wild) here where assumed `this.groups` was defined here, causing JS errors. This if() check fixes that bug.
        return;
      }

      this.activeGroup = this.groups[groupId];

      if (!this.activeGroup) {
        if (this.groupNotFoundId !== groupId) {
          //FIXME: Please see the issue here #3 in PR https://github.com/soracom/user-console-monorepo/pull/966, the group not found will usually come from switching coverage types but not clearing the url params.  This is expected.  However, for some reason the error is thrown twice, so here the id is checked to prevent the error from being displayed twice to the user.
          //What we really need to do is rewrite this page so strange errors like this are not occurring.
          this.groupNotFoundId = groupId;
          this.alertsService.showError(this.$translate.instant(`groups.error.group_not_found`, { groupId: groupId }));
        }
        return;
      }
      const groupName = this.activeGroup.name;
      const title = groupName ? `${groupName} (${groupId})` : groupId;

      this.breadcrumbService.overrideBreadcrumbs([
        BreadcrumbForDashboard,
        { labelKey: this.getPageTitleKey(), url: `/${this.path}`, classes: this.linkClass },
        { label: title },
      ]);
    }
    // If we don't have a group, then default route breadcrumbs are enough.
  }

  addGroup() {
    this.uiDsModalService
      .openAndWaitForResult(GroupAddModalComponent, {
        title: 'group_add.header',
      })
      .then((result) => {
        if (result?.group) {
          this.okAction(result);
        }
      });
  }

  private okAction = (result: { group: Group }) => {
    this.updateGroups().then(() => {
      this.showGroupDetails(result.group);
    });
  };

  reloadGroups() {
    this.updateGroups();
  }

  showGroupDetails(group: Group) {
    this.$route.updateParams({ id: group.groupId });
    this.$scope.$applyAsync();
  }

  // @ts-expect-error (legacy code incremental fix) FIXME: easy fix, should be fixed
  getRootCssClass(): string {
    if (this.isCellular) {
      return 'x-groups-page-root';
    } else if (this.isLorawan) {
      return 'x-lora_device_groups-page-root';
    } else if (this.isSigfox) {
      return 'x-sigfox_device_groups-page-root';
    } else if (this.isDevice) {
      return 'x-device-groups-page-root';
    } else if (this.isSatellite) {
      return 'x-satellite-device-groups-page-root';
    }
  }

  getPageTitleKey(): string {
    return `routes.title.${this.path}`;
  }

  hasData(): boolean {
    return groupsService.getCount() > 0;
  }

  isLoading() {
    return groupsService.isLoading();
  }

  getCount() {
    return groupsService.getCount();
  }

  onClick(group: LegacyAny) {
    this.showGroupDetails(group);
  }
}
