export const template = `<div
  class="ds-notice --legacy"
  uib-alert="uib-alert"
  ng-repeat="alert in service.alerts"
  type="{{ alert.type }}"
  ng-class="'--' + (alert.type || 'warning'); alert.type === 'danger' ? '--alert' : ''"
  close="service.closeAlertById(alert.id)"
  data-testid="alert-{{ alert.type }}"
>
  <span
    ng-bind="alert.text"
    ng-if="!alert.translationId"
  ></span>
  <span
    translate="{{ alert.translationId }}"
    translate-values="{{ alert.values }}"
    ng-if="alert.translationId"
  ></span>
</div>`;
