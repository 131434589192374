import dayjs from 'dayjs';

import { LegacyAny } from '@soracom/shared/core';

import { template } from './audit_logs.component.html';

import { SearchQuery } from '@user-console/legacy-soracom-api-client';
import { AlertsService, AlertsServiceInstance } from '../components/alerts.service';
import { BasePaginatableTableController } from '../components/base_paginatable_table_controller';
import {
  TableColumnOptionsService,
  TableColumnOptionsType,
} from '../../../src/app/ng-rewrites/legacy-table/table_column_options.service';
import { ApiEvent } from '../core/api_event';
import { InjectList } from '../core/injectable';
import { NapterAuditLogsService } from './napter_audit_logs.service';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';
import { LogsRawDataModalComponent } from '../../../src/app/napter/raw_data.modal.component';
import { NapterLog } from '@soracom/shared/soracom-platform';
import { RESOURCE_TYPE_IMSI, Resource } from 'apps/user-console/src/app/resource-selector/resource-selector.type';
import {
  LAST_30_DAYS,
  TimeRange,
  isRelativeTimeRange,
  resolveToFiniteAbsoluteTimeRange,
} from '@soracom/shared-ng/ui-common';

const MAX_RESOURCES = 1;
const retentionPeriodMonths = 18;

export interface AuditLogResourceSearchQuery {
  resource_id?: string;
  resource_type?: string;
}

/**
 * NOTE THAT THIS IS THE *NAPTER* AUDIT LOG COMPONENT. (That might not be obvious from the name, but it is for historical reasons.)
 */
export class AuditLogsComponent implements ng.IComponentOptions {
  controller = AuditLogsComponentController;
  template: any = template;
}

export class AuditLogsComponentController extends BasePaginatableTableController<NapterLog> {
  static $inject: InjectList = [
    '$location',
    '$log',
    '$translate',
    '$q',
    '$rootScope',
    '$routeParams',
    'AlertsService',
    'NapterAuditLogsService',
    'TableColumnOptionsService',
    'UiDsModalService',
  ];
  availableResourceTypes: string[] = [RESOURCE_TYPE_IMSI];

  resources: AuditLogResourceSearchQuery[] = [];

  resourcesInModernFormat: Resource[] = [];

  /**
   * I'm trying to minimize the changes required in order to remove Material dependencies. If I was a better person, I would have just eliminated the older (but very similar) `resource` format, but it is used by other components/services and I don't want to modify them all right now. So this just converts the old format to what the new modern date range UI component expects.
   */
  updateResourcesInModernFormat() {
    this.resourcesInModernFormat = this.resources
      .map((resource: LegacyAny) => {
        return {
          resourceType: resource.resource_type,
          resourceId: resource.resource_id,
        };
      })
      .filter((resource: LegacyAny) => resource.resourceId !== '');
  }

  searchTimeRange: TimeRange = LAST_30_DAYS;

  /**
   * masonmark 2023-11-23: NOTE: the modern date range UI component does not support min date yet, so this is unimplemented
   */
  minDate = dayjs().subtract(retentionPeriodMonths, 'months').valueOf();

  constructor(
    private $location: LegacyAny,
    $log: LegacyAny,
    $translate: LegacyAny,
    $q: LegacyAny,
    private $rootScope: ng.IRootScopeService,
    private $routeParams: LegacyAny,
    AlertsService: AlertsService,
    napterAuditLogsService: NapterAuditLogsService,
    private tableColumnOptionsService: TableColumnOptionsService,
    private uiDsModalService: UiDsModalService,
  ) {
    super($log, $translate, $q, AlertsService, napterAuditLogsService);
  }

  $onInit() {
    super.$onInit();
    this.trace(this);
    this.setTraceEnabled(true);

    this.resources = this.initResources();

    this.updateResourcesInModernFormat();

    const urlFromValue = Number(this.$routeParams.from);
    const urlToValue = Number(this.$routeParams.to);

    if (urlFromValue || urlToValue) {
      this.searchTimeRange = resolveToFiniteAbsoluteTimeRange(this.searchTimeRange);
      this.searchTimeRange.from = urlFromValue;
      this.searchTimeRange.to = urlToValue;
    }

    this.searchQuery = this.initSearchQuery();
    this.buildSearchQuery(this.resources[0]);
    this.setupColumnOptions(
      TableColumnOptionsType.NapterAuditLog,
      this.tableColumnOptionsService,
      this.uiDsModalService,
    );
    this.reloadData();

    this.$rootScope.$on(ApiEvent.CoverageTypeChanged.toString(), (event, coverageType) => {
      this.clearData();
      this.resources[0].resource_id = '';
      this.buildSearchQuery(this.resources[0]);
      this.updateUrl();
      this.reloadData();
    });
  }

  /**
   * This is where we get the list of resources from the multi-resource-selector component. But that component is modern, so we have to translate the event into a legacy format used by this legacy AngularJS component. Luckily that is easy because they are similar.
   *
   * Also it looks like this old code originally hoped to support multiple resources (e.g. getting logs for >1 SIM at a time, but the old code never did actually implement that, and so the MAX was always 1. This makes it even simpler.)
   *
   * @param $event — maybe like  `{type: "add", targetValue: {resourceType: "Subscriber", resourceId: "999999111848469", name: "HELLO BSINM"}, resources: [{resourceType: "Subscriber", resourceId: "999999111848469", name: "HELLO BSINM"}]}`
   */
  onResourcesChange($event: LegacyAny) {
    if ($event.resources.length > 0) {
      const modernResource = $event.resources[0];
      this.resources[0].resource_id = modernResource.resourceId;
      this.resources[0].resource_type = modernResource.resourceType; // really it is always 'Subscriber'
    } else {
      this.removeResource(this.resources[0]);
    }
    this.search();
  }

  searchTimeRangeChanged($event: LegacyAny) {
    if (isRelativeTimeRange($event)) {
      this.searchTimeRange = $event;
    } else {
      this.searchTimeRange = resolveToFiniteAbsoluteTimeRange($event);
    }

    this.search();
  }

  private initSearchQuery(): SearchQuery {
    const searchQuery = new SearchQuery();
    // @ts-expect-error (legacy code incremental fix)
    searchQuery.searchType = undefined;
    return searchQuery;
  }

  private _initResource(resourceType: string, resourceId: string): AuditLogResourceSearchQuery {
    if (resourceId && resourceType === 'Subscriber') {
      return { resource_id: resourceId, resource_type: resourceType };
    } else {
      // @ts-expect-error (legacy code incremental fix)
      return null;
    }
  }

  /**
   * This component supports URLs and bookmarking, so this method loads an existing search if one is reflected in the URL. Otherwise it sets up the default search.
   */
  private initResources(): AuditLogResourceSearchQuery[] {
    if (this.$routeParams.resource_id && this.$routeParams.resource_type) {
      const resource = this._initResource(this.$routeParams.resource_type, this.$routeParams.resource_id);
      if (resource) {
        return [resource];
      }
    } else if (this.$routeParams.resources) {
      return this.$routeParams.resources
        .split('&')
        .map((resourceString: LegacyAny) => {
          const resource = resourceString.split('#');
          return this._initResource(resource[0], resource[1]);
        })
        .filter((resource: LegacyAny) => resource !== null);
    }
    return [{ resource_id: '', resource_type: 'Subscriber' }];
  }

  removeResource(_resource: AuditLogResourceSearchQuery) {
    this.resources[0].resource_id = '';
  }

  addResource() {
    if (this.resources.length < MAX_RESOURCES) {
      this.resources.push({ resource_id: '', resource_type: 'Subscriber' });
    }
  }

  filterResource(log: NapterLog) {
    this.resources[0].resource_type = 'Subscriber';
    this.resources[0].resource_id = log.imsi;

    this.updateResourcesInModernFormat();
    this.search();
  }

  doesFilterResource(log: NapterLog) {
    return this.resources[0].resource_type === 'Subscriber' && this.resources[0].resource_id === log.imsi;
  }

  openRawData(log: NapterLog) {
    this.uiDsModalService.openAndWaitForResult(LogsRawDataModalComponent, {
      title: 'logs.modals.raw_data.title',
      data: {
        resolve: {
          log: () => {
            return log;
          },
        },
      },
    });
  }

  disableAddResourceButton(): boolean {
    return this.resources.length >= MAX_RESOURCES;
  }

  showRemoveResourceButton(): boolean {
    return this.resources.length > 1;
  }

  search() {
    this.uncheckAll();
    // At this point, resource is always 1
    this.buildSearchQuery(this.resources[0]);
    this.updateUrl();
    this.paginator.clearPagination();
    this.reloadData();
  }

  // isLoading(): boolean → handled by superclass

  private updateUrl() {
    const finiteTimeRange = resolveToFiniteAbsoluteTimeRange(this.searchTimeRange);
    if (this.resources.length === 0) {
      this.$location
        .search('from', finiteTimeRange.from)
        .search('to', finiteTimeRange.to)
        .search('resource_id', undefined)
        .search('resource_type', undefined);
    } else if (this.resources.length === 1) {
      const resource = this.resources[0];
      this.$location
        .search('from', finiteTimeRange.from)
        .search('to', finiteTimeRange.to)
        .search('resource_id', resource.resource_id || undefined)
        .search('resource_type', resource.resource_type);
    }
  }

  private static readonly defaultProxyLabel = '(napter)';

  // @ts-expect-error (legacy code incremental fix)
  directionSummary(obj: NapterLog): string {
    const direction = obj.direction;
    if (!direction) {
      return '';
    }

    // for backward compatibility
    const proxyIPAddress = direction.proxyIPAddress;
    const proxyPort = direction.proxyPort;
    const proxyLabel =
      proxyIPAddress && proxyPort ? `${proxyIPAddress}:${proxyPort}` : AuditLogsComponentController.defaultProxyLabel;

    switch (obj.type) {
      case 'ACCESS':
      case 'DENIED':
      case 'CONNECTED':
      case 'REFUSED':
      case 'CLOSED':
        // example:
        //   198.0.2.19:50965 > 198.51.100.22:22394 > 203.0.113.199:10002
        // or
        //   198.0.2.19:50965 > (Napter) > 203.0.113.199:10002
        return `${direction.sourceIPAddress}:${direction.sourcePort} <i class="ds-icon --icon-arrow-right --xtiny --color-ink-tint">&gt;</i> ${proxyLabel} <i class="ds-icon --icon-arrow-right --xtiny --color-ink-tint">&gt;</i> ${direction.destinationIPAddress}:${direction.destinationPort}`;
      case 'CREATED':
      case 'DELETED':
      case 'EXPIRED':
        // example:
        //   > 198.51.100.22:22394 > :10002
        // or
        //   > (Napter) > :10002
        return `<i class="ds-icon --icon-arrow-right --xtiny --color-ink-tint">&gt;</i> ${proxyLabel} <i class="ds-icon --icon-arrow-right --xtiny --color-ink-tint">&gt;</i> :${direction.destinationPort}`;
      default:
        const _: undefined = obj.type;
    }
  }

  private buildSearchQuery(resource: AuditLogResourceSearchQuery) {
    if (resource.resource_id) {
      this.searchQuery.setQueryItem('resource_type', resource.resource_type);
      this.searchQuery.setQueryItem('resource_id', resource.resource_id);
    } else {
      this.searchQuery.deleteQueryItem('resource_type');
      this.searchQuery.deleteQueryItem('resource_id');
    }

    const finiteTimeRange = resolveToFiniteAbsoluteTimeRange(this.searchTimeRange);

    this.searchQuery.setQueryItem('from', finiteTimeRange.from);
    this.searchQuery.setQueryItem('to', finiteTimeRange.to);
  }
}
