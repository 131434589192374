import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import objectSupport from 'dayjs/plugin/objectSupport';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { template } from './date_time_picker.component.html';

import { BaseController } from '../components/base_controller';
import { InjectList } from '../core/injectable';

dayjs.extend(advancedFormat);
dayjs.extend(objectSupport);
dayjs.extend(timezone);
dayjs.extend(utc);

export class DateTimePickerComponent implements ng.IComponentOptions {
  bindings = {
    dateTimeMsec: '=',
    required: '<?',
    fieldName: '<?',
    minDate: '<?',
    maxDate: '<?',
    noTime: '<?',
    noTimeZone: '<?',
    dateFieldSize: '<?',
    noDefaultDate: '<?',
    utc: '<?',
    sds: '<?',
  };

  controller = DateTimePickerComponentController;
  template: any = template;
}

export class DateTimePickerComponentController extends BaseController {
  static $inject: InjectList = ['$log'];

  // @ts-expect-error (legacy code incremental fix)
  dateTimeMsec: number;
  // @ts-expect-error (legacy code incremental fix)
  required: boolean;
  // @ts-expect-error (legacy code incremental fix)
  fieldName: string;
  // @ts-expect-error (legacy code incremental fix)
  minDate: dayjs.Dayjs;
  // @ts-expect-error (legacy code incremental fix)
  maxDate: dayjs.Dayjs;
  // @ts-expect-error (legacy code incremental fix)
  noTime: boolean;
  // @ts-expect-error (legacy code incremental fix)
  noTimeZone: boolean;
  // @ts-expect-error (legacy code incremental fix)
  dateFieldSize: number;
  // @ts-expect-error (legacy code incremental fix)
  noDefaultDate: boolean;
  // @ts-expect-error (legacy code incremental fix)
  utc: boolean;

  inputType: 'date' | 'datetime-local' = 'datetime-local';
  selectedDateTime: Date | null = null;
  minDateTime: string | null = null;
  maxDateTime: string | null = null;
  // @ts-expect-error (legacy code incremental fix)
  dateFieldName: string;
  // @ts-expect-error (legacy code incremental fix)
  timeFieldName: string;
  // @ts-expect-error (legacy code incremental fix)
  timezone: string;

  constructor($log: ng.ILogService) {
    super($log);
  }

  $onInit() {
    this.initializeDateAndTime();
    if (this.required === undefined || this.required === null) {
      this.required = true;
    }

    if (this.fieldName !== undefined && this.fieldName !== null) {
      this.dateFieldName = this.fieldName + '-date';
      this.timeFieldName = this.fieldName + '-time';
    } else {
      this.dateFieldName = 'date';
      this.timeFieldName = 'time';
    }

    if (this.utc) {
      this.timezone = 'UTC';
    } else {
      const guessedTimezone = dayjs.tz.guess();
      // dayjs always display "GMT+X/GMT-X". This is special treatment for JST users.
      if (guessedTimezone === 'Asia/Tokyo') {
        this.timezone = 'JST';
      } else {
        // use +HH:mm format instead of "GMT+X/GMT-X"
        this.timezone = dayjs().format('Z');
      }
    }
  }

  initializeDateAndTime() {
    this.inputType = this.noTime ? 'date' : 'datetime-local';
    const defaultDate = this.dateTimeMsec > 0 ? dayjs(this.dateTimeMsec).startOf('minute') : dayjs().startOf('day');
    // dayjs manipulate methods (hour, minute, second) are destructive methods.
    this.selectedDateTime = defaultDate.toDate();

    if (this.noDefaultDate) {
      this.selectedDateTime = null;
    }
    if (this.minDate) {
      this.minDateTime = this.noTime ? this.minDate.format('YYY-MM-DD') : this.minDate.format('YYYY-MM-DDTHH:mm');
    }
    if (this.maxDate) {
      this.maxDateTime = this.noTime ? this.maxDate.format('YYY-MM-DD') : this.maxDate.format('YYYY-MM-DDTHH:mm');
    }
  }

  updateDateTime() {
    const dateTime = this.selectedDateTime;
    if (dateTime !== undefined && dateTime !== null) {
      const dt = dayjs(dateTime);
      const d = this.utc ? dayjs.utc : dayjs;
      this.dateTimeMsec = d({
        year: dt.year(),
        month: dt.month(),
        date: dt.date(),
        hour: dt.hour(),
        minute: dt.minute(),
        second: dt.second(),
      }).valueOf();
    } else {
      // @ts-expect-error (legacy code incremental fix)
      this.dateTimeMsec = null;
    }
  }

  // pickerCSS() {
  //   return this.sds ? ['ds-date-time-picker'] : ['form-inline'];
  // }

  // pickerWrapperCSS() {
  //   return this.sds ? ['ds-field'] : ['form-group'];
  // }

  // pickerInputCSS() {
  //   return this.sds ? ['ds-input'] : ['form-control'];
  // }
}
