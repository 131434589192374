<form [formGroup]="form">
  <label class="ds-checkbox">
    <input type="checkbox" formControlName="autoAdjust" />
    <span class="ds-text --label">{{ 'data.chart.y_range_automatic_adjustment' | translate }}</span>
  </label>
  <label class="ds-field">
    <div class="ds-text --label">{{ 'data.chart.y_max_label' | translate }}</div>
    <input class="ds-input" type="number" formControlName="maxValue" />
  </label>
  <label class="ds-field">
    <div class="ds-text --label">{{ 'data.chart.y_min_label' | translate }}</div>
    <input class="ds-input" type="number" formControlName="minValue" />
  </label>
</form>
