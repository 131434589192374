// See: "Making AngularJS Dependencies Injectable to Angular"
// https://angular.io/guide/upgrade#making-angularjs-dependencies-injectable-to-angular

import { InjectionToken } from '@angular/core';
import { ButtonsService } from 'apps/user-console/app/shared/buttons/buttons.service';
import { AlertsService } from 'apps/user-console/app/shared/components/alerts.service';
import { AddressService } from 'apps/user-console/app/shared/shipping_addresses/address.service';
import { SoraCamCellularPacksService } from 'apps/user-console/app/shared/sora_cam/sora_cam_cellular_pack.service';
import { NapterAuditLogsService } from '../../app/shared/audit_logs/napter_audit_logs.service';
import { BillingsService } from '../../app/shared/billings/billings.service';
import { AuthService } from '../../app/shared/components/auth.service';
import { CountryService } from '../../app/shared/components/country.service';
import { CoverageTypeService } from '@soracom/shared/data-access-auth';
import { PaginationService } from '@user-console/legacy-soracom-api-client';
import { CompanyInformationService } from '../../app/shared/contact_information/company_information.service';
import { IndividualInformationService } from '../../app/shared/contact_information/individual_information.service';
import { PostalAddressService } from '../../app/shared/contact_information/postal_address.service';
import { DevicesService } from '../../app/shared/devices/devices.service';
import { GroupsService } from '../../app/shared/groups/groups.service';
import { HarvestDataService } from '../../app/shared/harvest_data/harvest_data.service';
import { HarvestFilesService } from '../../app/shared/harvest_files/harvest_files.service';
import { LagoonService } from '../../app/shared/lagoon/lagoon.service';
import { LagoonDashboardsService } from '../../app/shared/lagoon/lagoon_dashboards.service';
import { LagoonUsersService } from '../../app/shared/lagoon/lagoon_users.service';
import { LogsService } from '../../app/shared/logs/logs.service';
import { LoraDevicesService } from '../../app/shared/lora_devices/lora_devices.service';
import { LoraNetworkSetsService } from '../../app/shared/lora_network_sets/lora_network_sets.service';
import { NavigationInterceptionService } from '../../app/shared/main/navigation_interception.service';
import { OperatorContractsService } from '../../app/shared/operators/operator_contracts.service';
import { OrdersService } from '../../app/shared/orders/orders.service';
import { CredentialsSetsService } from '../../app/shared/security/credentials_sets.service';
import { DefaultPermissionsService } from '../../app/shared/security/default_permissions.service';
import { RolesService } from '../../app/shared/security/modals/roles.service';
import { UsersService } from '../../app/shared/security/users.service';
import { UserRolesService } from '../../app/shared/security/user_roles.service';
import { ShippingAddressesService } from '../../app/shared/shipping_addresses/shipping_addresses.service';
import { SigfoxDevicesService } from '../../app/shared/sigfox_devices/sigfox_devices.service';
import { SoraletsService } from '../../app/shared/soralets/soralets.service';
import { SoraletLogsService } from '../../app/shared/soralets/soralet_logs.service';
import { SoraletVersionsService } from '../../app/shared/soralets/soralet_versions.service';
import { NgLocationService } from '../../app/shared/subscribers/ng_location.service';
import { SimsService } from '../../app/shared/subscribers/sims.service';
import { SubscribersService } from '../../app/shared/subscribers/subscribers.service';
import { VirtualPrivateGatewaysService } from '../../app/shared/virtual_private_gateways/virtual_private_gateways.service';

export const GROUPS_SERVICE = new InjectionToken<GroupsService>('GroupsService');
export const SUBSCRIBERS_SERVICE = new InjectionToken<SubscribersService>('SubscribersService');
export const LAGOON_SERVICE = new InjectionToken<LagoonService>('LagoonService');

// boilerplate
//
// export function aaaServiceFactory(injector: any) {
//   return injector.get('AaaService');
// }
//
// export const aaaServiceProvider = {
//   provide: AaaService,
//   useFactory: aaaServiceFactory,
//   deps: ['$injector']
// };

// ⚠️ WARNING! ⚠️
//
// THIS FILE IS EASILY CORRUPTED BY THE AUTO-MERGE FEATURE WHEN MERGING PULL REQUESTS AND BRANCHES!
//
// IT WILL USUALLY MERGE WRONGLY, AND SWAP THE SERVICE PROVIDER NAMES AROUND.
//
// So, it's best to double-check this file after every merge, and make sure the service providers are in the right order.

export function addressServiceFactory(injector: any) {
  return injector.get('AddressService');
}

export const addressServiceProvider = {
  provide: AddressService,
  useFactory: addressServiceFactory,
  deps: ['$injector'],
};

export function alertsServiceFactory(injector: any) {
  return injector.get('AlertsService');
}

export const alertsServiceProvider = {
  provide: AlertsService,
  useFactory: alertsServiceFactory,
  deps: ['$injector'],
};

export function authServiceFactory(injector: any) {
  return injector.get('AuthService');
}

export const authServiceProvider = {
  provide: AuthService,
  useFactory: authServiceFactory,
  deps: ['$injector'],
};

// TODO: migrate billings service to TypeScript and uncomment it.
// export function billingsServiceFactory(injector: any) {
//   return injector.get('BillingsService');
// }
//
// export const billingServiceProvider = {
//   provide: BillingsService,
//   useFactory: billingsServiceFactory,
//   deps: ['$injector']
// };

export function billingsServiceFactory(injector: any) {
  return injector.get('BillingsService');
}

export const billingsServiceProvider = {
  provide: BillingsService,
  useFactory: billingsServiceFactory,
  deps: ['$injector'],
};

export function companyInformationServiceFactory(injector: any) {
  return injector.get('CompanyInformationService');
}

export const companyInformationServiceProvider = {
  provide: CompanyInformationService,
  useFactory: companyInformationServiceFactory,
  deps: ['$injector'],
};

export function buttonsServiceFactory(injector: any) {
  return injector.get('ButtonsService');
}

export const buttonsServiceProvider = {
  provide: ButtonsService,
  useFactory: buttonsServiceFactory,
  deps: ['$injector'],
};

export function coverageTypeServiceFactory(injector: any) {
  return injector.get('CoverageTypeService');
}

export const coverageTypeServiceProvider = {
  provide: CoverageTypeService,
  useFactory: coverageTypeServiceFactory,
  deps: ['$injector'],
};

export function credentialsSetsServiceFactory(injector: any) {
  return injector.get('CredentialsSetsService');
}

export const credentialsSetsServiceProvider = {
  provide: CredentialsSetsService,
  useFactory: credentialsSetsServiceFactory,
  deps: ['$injector'],
};

export function defaultPermissionsServiceFactory(injector: any) {
  return injector.get('DefaultPermissionsService');
}

export const defaultPermissionsServiceProvider = {
  provide: DefaultPermissionsService,
  useFactory: defaultPermissionsServiceFactory,
  deps: ['$injector'],
};

export const rolesServiceProvider = {
  provide: RolesService,
  useFactory: (injector: any) => injector.get('RolesService'),
  deps: ['$injector'],
};

export function groupsServiceFactory(injector: any) {
  return injector.get('GroupsService');
}

export const groupsServiceProvider = {
  provide: GroupsService,
  useFactory: groupsServiceFactory,
  deps: ['$injector'],
};

export const groupsServiceTokenProvider = {
  provide: GROUPS_SERVICE,
  useFactory: groupsServiceFactory,
  deps: ['$injector'],
};

export function harvestFilesServiceFactory(injector: any) {
  return injector.get('HarvestFilesService');
}

export const harvestFilesServiceProvider = {
  provide: HarvestFilesService,
  useFactory: harvestFilesServiceFactory,
  deps: ['$injector'],
};

export function individualInformationServiceFactory(injector: any) {
  return injector.get('IndividualInformationService');
}

export const individualInformationServiceProvider = {
  provide: IndividualInformationService,
  useFactory: individualInformationServiceFactory,
  deps: ['$injector'],
};

export function lagoonDashboardsServiceFactory(injector: any) {
  return injector.get('LagoonDashboardsService');
}

export const lagoonDashboardsServiceProvider = {
  provide: LagoonDashboardsService,
  useFactory: lagoonDashboardsServiceFactory,
  deps: ['$injector'],
};

export function lagoonServiceFactory(injector: any) {
  return injector.get('LagoonService');
}

export const lagoonServiceProvider = {
  provide: LagoonService,
  useFactory: lagoonServiceFactory,
  deps: ['$injector'],
};

export const lagoonServiceTokenProvider = {
  provide: LAGOON_SERVICE,
  useFactory: lagoonServiceFactory,
  deps: ['$injector'],
};

export function lagoonUsersServiceFactory(injector: any) {
  return injector.get('LagoonUsersService');
}

export const lagoonUsersServiceProvider = {
  provide: LagoonUsersService,
  useFactory: lagoonUsersServiceFactory,
  deps: ['$injector'],
};

export function countryServiceFactory(injector: any) {
  return injector.get('CountryService');
}

export const countryServiceProvider = {
  provide: CountryService,
  useFactory: countryServiceFactory,
  deps: ['$injector'],
};

export function navigationInterceptionServiceFactory(injector: any) {
  return injector.get('NavigationInterceptionService');
}

export const navigationInterceptionServiceProvider = {
  provide: NavigationInterceptionService,
  useFactory: navigationInterceptionServiceFactory,
  deps: ['$injector'],
};

export function ngLocationServiceFactory(injector: any) {
  return injector.get('NgLocationService');
}

export const ngLocationServiceProvider = {
  provide: NgLocationService,
  useFactory: ngLocationServiceFactory,
  deps: ['$injector'],
};

export function ordersServiceFactory(injector: any) {
  return injector.get('OrdersService');
}

export const ordersServiceProvider = {
  provide: OrdersService,
  useFactory: ordersServiceFactory,
  deps: ['$injector'],
};

export function paginationServiceFactory(injector: any) {
  return injector.get('PaginationService');
}

export const paginationServiceProvider = {
  provide: PaginationService,
  useFactory: paginationServiceFactory,
  deps: ['$injector'],
};

export function postalServiceFactory(injector: any) {
  return injector.get('PostalAddressService');
}

export const postalServiceProvider = {
  provide: PostalAddressService,
  useFactory: postalServiceFactory,
  deps: ['$injector'],
};

export function shippingAddressServiceFactory(injector: any) {
  return injector.get('ShippingAddressesService');
}

export const shippingAddressesServiceProvider = {
  provide: ShippingAddressesService,
  useFactory: shippingAddressServiceFactory,
  deps: ['$injector'],
};

export function soraCamCellularPacksServiceFactory(injector: any) {
  return injector.get('SoraCamCellularPacksService');
}

export const soraCamCellularPacksServiceProvider = {
  provide: SoraCamCellularPacksService,
  useFactory: soraCamCellularPacksServiceFactory,
  deps: ['$injector'],
};


export function soraletsServiceFactory(injector: any) {
  return injector.get('SoraletsService');
}

export const soraletsServiceProvider = {
  provide: SoraletsService,
  useFactory: soraletsServiceFactory,
  deps: ['$injector'],
};

export function soraletVersionsServiceFactory(injector: any) {
  return injector.get('SoraletVersionsService');
}

export const soraletVersionsServiceProvider = {
  provide: SoraletVersionsService,
  useFactory: soraletVersionsServiceFactory,
  deps: ['$injector'],
};

export function soraletLogsServiceFactory(injector: any) {
  return injector.get('SoraletLogsService');
}

export const soraletLogsServiceProvider = {
  provide: SoraletLogsService,
  useFactory: soraletLogsServiceFactory,
  deps: ['$injector'],
};

export function subscribersServiceFactory(injector: any) {
  return injector.get('SubscribersService');
}

export const subscribersServiceProvider = {
  provide: SubscribersService,
  useFactory: subscribersServiceFactory,
  deps: ['$injector'],
};

export const subscribersServiceTokenProvider = {
  provide: SUBSCRIBERS_SERVICE,
  useFactory: subscribersServiceFactory,
  deps: ['$injector'],
};

export function simsServiceFactory(injector: any) {
  return injector.get('SimsService');
}

export const simsServiceProvider = {
  provide: SimsService,
  useFactory: simsServiceFactory,
  deps: ['$injector'],
};

export function devicesServiceFactory(injector: any) {
  return injector.get('DevicesService');
}

export const devicesServiceProvider = {
  provide: DevicesService,
  useFactory: devicesServiceFactory,
  deps: ['$injector'],
};

export function loraDevicesServiceFactory(injector: any) {
  return injector.get('LoraDevicesService');
}

export const loraDevicesServiceProvider = {
  provide: LoraDevicesService,
  useFactory: loraDevicesServiceFactory,
  deps: ['$injector'],
};

export function sigfoxDevicesServiceFactory(injector: any) {
  return injector.get('SigfoxDevicesService');
}

export const sigfoxDevicesServiceProvider = {
  provide: SigfoxDevicesService,
  useFactory: sigfoxDevicesServiceFactory,
  deps: ['$injector'],
};

export function harvestDataServiceFactory(injector: any) {
  return injector.get('HarvestDataService');
}

export const harvestDataServiceProvider = {
  provide: HarvestDataService,
  useFactory: harvestDataServiceFactory,
  deps: ['$injector'],
};

export function virtualPrivateGatewaysServiceFactory(injector: any) {
  return injector.get('VirtualPrivateGatewaysService');
}

export const virtualPrivateGatewaysServiceProvider = {
  provide: VirtualPrivateGatewaysService,
  useFactory: virtualPrivateGatewaysServiceFactory,
  deps: ['$injector'],
};

export function loraNetworkSetsServiceFactory(injector: any) {
  return injector.get('LoraNetworkSetsService');
}

export const loraNetworkSetsServiceProvider = {
  provide: LoraNetworkSetsService,
  useFactory: loraNetworkSetsServiceFactory,
  deps: ['$injector'],
};

export function usersServiceFactory(injector: any) {
  return injector.get('UsersService');
}

export const usersServiceProvider = {
  provide: UsersService,
  useFactory: usersServiceFactory,
  deps: ['$injector'],
};

export function userRolesServiceFactory(injector: any) {
  return injector.get('UserRolesService');
}

export const userRolesServiceProvider = {
  provide: UserRolesService,
  useFactory: userRolesServiceFactory,
  deps: ['$injector'],
};

export function errorLogsServiceFactory(injector: any) {
  // TODO rename LogsService on the NG side to ErrorLogsService
  return injector.get('LogsService');
}

export const errorLogsServiceProvider = {
  provide: LogsService,
  useFactory: errorLogsServiceFactory,
  deps: ['$injector'],
};

export function napterAuditLogServiceFactory(injector: any) {
  return injector.get('NapterAuditLogsService');
}

export const napterAuditLogServiceProvider = {
  provide: NapterAuditLogsService,
  useFactory: napterAuditLogServiceFactory,
  deps: ['$injector'],
};

export function operatorContractsServiceFactory(injector: any) {
  return injector.get('OperatorContractsService');
}

export const operatorContractsServiceProvider = {
  provide: OperatorContractsService,
  useFactory: operatorContractsServiceFactory,
  deps: ['$injector'],
};
