import { LegacyAny } from '@soracom/shared/core';

import { Taggable } from '@soracom/shared/soracom-platform';
import { BaseModel } from '@soracom/shared/soracom-platform';
import { LoraGateway } from './lora_gateway';

export class LoraNetworkSet extends BaseModel implements Taggable {
  static resourcePath = 'lora_network_sets';

  // @ts-expect-error (legacy code incremental fix)
  allowedOperators: string[];
  // @ts-expect-error (legacy code incremental fix)
  createdTime: number;
  // @ts-expect-error (legacy code incremental fix)
  lastModifiedTime: number;
  // @ts-expect-error (legacy code incremental fix)
  networkSetId: string;
  // @ts-expect-error (legacy code incremental fix)
  operatorId: string;
  tags: { [id: string]: any };
  loraGateways: LoraGateway[];

  constructor(params: LegacyAny) {
    super();

    this.loraGateways = [];
    this.tags = {};
    if (params) {
      this.networkSetId = params.networkSetId;
      this.createdTime = params.createdTime;
      this.lastModifiedTime = params.lastModifiedTime;
      this.networkSetId = params.networkSetId;
      this.operatorId = params.operatorId;
      this.allowedOperators = params.allowedOperators || [];
      this.tags = params.tags;
    }
  }

  get name() {
    return this.tags?.name;
  }

  get id(): string {
    return this.networkSetId;
  }
}
