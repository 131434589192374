import { LegacyAny } from '@soracom/shared/core';

import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { merge } from 'lodash-es';
import { Observable, from, of, merge as rxjsMerge, take as takeNumber, timer } from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  mapTo,
  pairwise,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { HttpLinkHeaderElement } from '@user-console/legacy-soracom-api-client';
import { ScRelation } from '../../../app/shared/components/paginator';
import { HarvestData } from '../../../app/shared/core/harvest_data';
import {
  HarvestDataPrevQuery,
  HarvestDataQuery,
  HarvestDataService,
} from '../../../app/shared/harvest_data/harvest_data.service';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { LAST_24_HOURS, TimeRange, isAbsoluteTimeRange, resolveTimeRange } from '@soracom/shared-ng/ui-common';
import { HarvestDataParam } from './harvest-data-route-param';
import { DataSeriesVisibility, HarvestDataVizType } from './harvest-data-viz/harvest-data-viz-type';
import { LABEL_RESOURCE_SEPARATOR } from './harvest-data-viz/viz-container/harvest-data-viz-container.component';
import { HarvestResource } from './harvest-data.type';
import { MapData } from './harvest-data-viz/map-container/harvest-data-map-container.component';
import { AuthService } from '@soracom/shared/data-access-auth';

type ResourceDataState = {
  /** values must be sorted by timestamp in descending order */
  values: HarvestData[];
  prevLink?: HttpLinkHeaderElement;
};

export interface HarvestDataState {
  loading: boolean;
  resources: HarvestResource[];
  timeRange: TimeRange;
  data: {
    [resourceId: string]: ResourceDataState;
  };
  itemsPerPage: number;
  currentPageIndex: number;
  autoRefreshEnabled: boolean;
  autoRefreshSwitchEnabled: boolean;
  vizType: HarvestDataVizType;
  pageVisible: boolean;
  visibilities: DataSeriesVisibility;
  aiDialogOpen: boolean;
  mapDisplayData: MapData[];
}

const INTERVAL_IN_SEC_FOR_1_RESOURCE = 5;
const INTERVAL_IN_SEC_FOR_MULTI_RESOURCES = 60;

// emit 5,4,3,2,1,5,4,...
const intervalCountdown = (intervalInSec: number) =>
  timer(0, 1000).pipe(map((n) => intervalInSec - (n % intervalInSec)));

const DEFAULT_STATE = (): HarvestDataState => ({
  loading: false,
  resources: [],
  timeRange: { ...LAST_24_HOURS },
  data: {},
  itemsPerPage: 50,
  currentPageIndex: 0,
  autoRefreshEnabled: false, //whether or not the autorefresh property is on
  autoRefreshSwitchEnabled: true, //whethre or not the UI switch for autorefresh is enabled
  vizType: 'table',
  pageVisible: true,
  visibilities: {},
  aiDialogOpen: false,
  mapDisplayData: [] as MapData[],
});

@Injectable()
export class HarvestDataStore extends ComponentStore<HarvestDataState> {
  constructor(
    private api: HarvestDataService,
    private alertsManager: AlertsManager,
    private authService: AuthService,
  ) {
    super(DEFAULT_STATE());
    this.initAutoRefresh();
    this._search(this.loadTrigger$);
    this.authService.authEvent$
      .pipe(
        filter((e) => e.type === 'logout' || e.type === 'expired'),
        take(1),
      )
      .subscribe(() => {
        this.setAutoRefresh(false);
        this.setAutoRefreshSwitchEnabled(false);
      });
  }

  public initialize(param: Partial<HarvestDataState>) {
    // undefined values in the `param` will be ignored
    const initialState: HarvestDataState = merge(DEFAULT_STATE(), param);
    this.setState(initialState);
    if (initialState.resources.length > 0) {
      this.search();
    }
  }

  handleError(error: any) {
    const alert = Alert.fromApiError(error);
    if (alert) {
      this.alertsManager.add(alert);
    } else {
      console.error(error);
    }
  }

  /*
    Queries
  */
  readonly resources$ = this.select((state) => state.resources);
  readonly numResources$ = this.select(this.resources$, (resources) => resources?.length ?? 0);

  readonly isMultiResource$ = this.select(this.resources$, (resources) => resources.length > 1);

  readonly timeRange$ = this.select((state) => state.timeRange);

  readonly visibilities$ = this.select((state) => state.visibilities);

  readonly mapDisplayData$ = this.select((state) => state.mapDisplayData);

  // fire on change of resources and timerange && ready to load (= hasResource)
  private readonly loadTrigger$ = rxjsMerge(
    this.resources$,
    this.timeRange$,
    this.select((s) => s.itemsPerPage).pipe(
      // trigger if value change && new > old, as we already have enough data for smaller page
      startWith(0),
      pairwise(),
      filter(([prev, cur]) => cur > prev),
    ),
  ).pipe(
    debounceTime(10), // easy way to prevent multiple update at a time
    mapTo(null),
  );

  readonly rawData$ = this.select((state) => state.data);

  readonly autoRefresh$ = this.select(
    this.isMultiResource$,
    this.select((s) => s.autoRefreshEnabled),
    (multi, enabled) => ({
      enabled,
      intervalInSec: multi ? INTERVAL_IN_SEC_FOR_MULTI_RESOURCES : INTERVAL_IN_SEC_FOR_1_RESOURCE,
    }),
  );

  readonly autoRefreshSwitchEnabled$ = this.select((s) => s.autoRefreshSwitchEnabled);

  private readonly _arCountDown$ = this.autoRefresh$.pipe(
    switchMap(({ enabled, intervalInSec }) =>
      (enabled ? intervalCountdown(intervalInSec) : of(-1)).pipe(map((n) => [n, intervalInSec])),
    ),
  );

  readonly autoRefreshDisplayCountDown$ = this._arCountDown$.pipe(map(([n, interval]) => (n === -1 ? interval : n)));

  /** Observable that emits event on every auto-refresh interval */
  readonly autoRefreshInterval$ = this._arCountDown$.pipe(
    filter(([num, intervalInSec]) => num === intervalInSec),
    mapTo(null),
  );

  /** All values sorted in latest-first order */
  readonly allItems$ = this.rawData$.pipe(
    map((data) => {
      // TODO: use flatMap once we update typescript target and/or libraries
      // @ts-expect-error (legacy code incremental fix)
      const values = Object.values(data).reduce((acc, cur) => [...acc, ...cur.values], []) as HarvestData[];
      // Note: Each resource data arrays are already sorted.
      // So we have room to improve speed by using smarter algorithms like merge-sort instead of the current concat & sort if we need
      values.sort((a: LegacyAny, b: LegacyAny) => b.time - a.time);
      return values;
    }),
  );

  readonly page$ = this.select(
    this.select((s) => s.itemsPerPage),
    this.select((s) => s.currentPageIndex),
    this.allItems$,
    (itemsPerPage, currentPageIndex, allItems) => {
      const isEmpty = allItems.length === 0;
      const firstItemIndex = itemsPerPage * currentPageIndex;
      const lastItemIndex = Math.min(isEmpty ? 0 : allItems.length - 1, itemsPerPage * (currentPageIndex + 1) - 1);

      return {
        itemsPerPage: itemsPerPage,
        currentPageIndex: currentPageIndex,
        /** 0-origin */ firstItemIndex,
        /** 0-origin */ lastItemIndex,
      };
    },
    { debounce: true },
  );

  readonly displayItems$ = this.select(
    this.allItems$,
    this.select(this.page$, (p) => p.firstItemIndex),
    this.select(this.page$, (p) => p.lastItemIndex),
    (all, first, last) => all.slice(first, last + 1),
    { debounce: true },
  );

  getDisplayItems: () => HarvestData[] = (() => {
    let latest: HarvestData[] = [];
    this.displayItems$.pipe(takeUntil(this.destroy$)).subscribe((items) => {
      latest = items;
    });
    return () => latest;
  })();

  readonly displayItemsInfo$ = this.select(this.displayItems$, (items) => ({
    num: items.length,
    displayStartDate: items.length === 0 ? null : items[items.length - 1].time,
    displayEndDate: items.length === 0 ? null : items[0].time,
  }));

  readonly paginationState$ = this.select(
    this.page$,
    this.select(this.rawData$, (data) => Object.values(data).some((rs) => !!rs.prevLink?.url)),
    this.select(this.allItems$, (all) => all.length),
    (page, hasPrevLink, allItemsLength) => {
      const isEmpty = allItemsLength === 0;
      const hasPrevItems = allItemsLength > page.itemsPerPage * (page.currentPageIndex + 1);

      return {
        ...page,
        hasPrev: hasPrevLink || hasPrevItems,
        hasNext: page.currentPageIndex > 0,
        /** 1-origin */ firstItemDisplayIndex: isEmpty ? 0 : page.firstItemIndex + 1,
        /** 1-origin */ lastItemDisplayIndex: isEmpty ? 0 : page.lastItemIndex + 1,
      };
    },
    { debounce: true },
  );

  readonly vizType$ = this.select((s) => s.vizType);

  readonly aiDialogOpen$ = this.select((s) => s.aiDialogOpen);

  readonly routeParams$: Observable<HarvestDataParam> = this.select(
    this.resources$,
    this.timeRange$,
    this.autoRefresh$,
    this.select((s) => s.itemsPerPage),
    this.vizType$,
    (resources, timeRange, ar, itemsPerPage, vizType) => ({
      resources,
      timeRange,
      autoRefreshEnabled: ar.enabled,
      itemsPerPage,
      vizType,
    }),
  );

  /**  For viz components*/
  get resources() {
    return this.get().resources;
  }

  get timeRange() {
    return this.get().timeRange;
  }

  get vizType() {
    return this.get().vizType;
  }

  get mapDisplayData() {
    return this.get().mapDisplayData;
  }

  /*
    Commands
  */
  addResource = (resource: HarvestResource) => {
    this.patchState((state) => ({ resources: [...state.resources, resource], autoRefreshEnabled: false }));
  };

  removeResource = (resourceId: string) => {
    this.patchState((state) => {
      const resources = state.resources.filter((r) => r.resourceId !== resourceId);
      const newData = { ...state.data };
      delete newData[resourceId];

      return {
        resources,
        data: newData,
        currentPageIndex: 0,
        autoRefreshEnabled: false,
      };
    });
  };

  setTimeRange = (timeRange: TimeRange) => {
    this.patchState({ timeRange, autoRefreshEnabled: false });
  };

  setLoading = (loading: boolean = true) => {
    this.patchState({ loading });
  };

  setVizType = (vizType: HarvestDataVizType) => {
    this.patchState({ vizType });
  };

  setVisibilities = (visibilities: DataSeriesVisibility) => {
    this.patchState({ visibilities });
  };

  setAiDialogOpen = (aiDialogOpen: boolean) => {
    this.patchState({ aiDialogOpen });
  };

  /**Get the visibility settings defined by the user on the left side of the data chart.
   * @param showResourceLabels If true and more than one device is selected, the resource name is included in the key of the returned object. If false, the resource name is not included in the key of the returned object, even in the case of multiple devices.
   */
  getVisibilities: (showResourceLabels?: boolean) => DataSeriesVisibility = (showResourceLabels = true) => {
    let curVisibilities: DataSeriesVisibility = this.get().visibilities ?? {};
    return showResourceLabels ? curVisibilities : removeVisibilityResourceLabels(curVisibilities);
  };

  setItemsPerPage = (n: string | number) => {
    const num = typeof n === 'string' ? Number.parseInt(n) : n;
    if (Number.isFinite(num) && num > 0) {
      this.patchState({
        itemsPerPage: num,
        currentPageIndex: 0,
      });
    }
  };

  setPageVisible = (pageVisible: boolean) => {
    this.patchState({ pageVisible });
  };

  /** Request data apis for each resource and replace data with results */
  search = () => this._search(null);
  private _search = this.effect(($: Observable<any>) => {
    return $.pipe(
      withLatestFrom(this.state$),
      filter(([, state]) => !state.loading), // skip if already loading
      tap(() => this.setLoading(true)),
      withLatestFrom(this.state$),
      switchMap(([, s]) => {
        const queries = generateSearchQueries(s.resources, s.timeRange, s.itemsPerPage);
        return from(this.callApis(queries));
      }),
      tap(({ data, errors }) => {
        this.patchState({ data, currentPageIndex: 0 }); // error resources will be omit from data
        errors.forEach((error: LegacyAny) => {
          this.handleError(error?.error);
        });
      }),
      tap(() => this.setLoading(false)),
    );
  });

  initAutoRefresh = () => {
    this.refresh(this.autoRefreshInterval$);
  };

  setAutoRefresh = this.effect((enabled$: Observable<boolean>) =>
    enabled$.pipe(
      withLatestFrom(this.timeRange$),
      tap(([enabled, timeRange]) => {
        const patch = { autoRefreshEnabled: enabled } as Partial<HarvestDataState>;

        // `to` in timeRange should be `null` (= open range)
        if (enabled && isAbsoluteTimeRange(timeRange) && timeRange.to !== null) {
          patch.timeRange = {
            from: timeRange.from,
            to: null,
          };
        }
        this.patchState(patch);

        if (enabled) {
          this.search(); // 1st refresh replaces existing data
        }
      }),
    ),
  );

  setAutoRefreshSwitchEnabled(switchEnabled: boolean) {
    this.patchState({ autoRefreshSwitchEnabled: switchEnabled });
  }

  // fetch new records and prepend data
  refresh = this.effect(($: Observable<any>) => {
    return $.pipe(
      withLatestFrom(this.state$),
      filter(([, s]) => !s.loading && s.pageVisible),
      tap(() => this.setLoading(true)),
      withLatestFrom(this.state$),
      switchMap(([, s]) => {
        const queries = generateSearchQueries(s.resources, s.timeRange, s.itemsPerPage);
        queries.forEach((q) => {
          // set `from` for query after the existing latest record
          const values = s.data[q.resourceId]?.values;
          if (values && values.length) {
            q.from = values[0].time + 1;
          }
          // @ts-expect-error (legacy code incremental fix)
          q.to = null;
        });
        return from(this.callApis(queries));
      }),
      withLatestFrom(this.rawData$),
      tap(([{ data, errors }, currentData]: [ApisResult, HarvestDataState['data']]) => {
        const newData = { ...currentData };
        let changed = false;

        Object.entries(data).forEach(([resourceId, fetched]) => {
          if (fetched.values?.length > 0) {
            changed = true;
            if (newData[resourceId]) {
              // prepend fetched data
              newData[resourceId].values = [...fetched.values, ...newData[resourceId].values];
            } else {
              newData[resourceId] = fetched;
            }
          }
        });
        if (changed) {
          this.patchState({ data: newData, currentPageIndex: 0 });
        }
        errors.forEach((error: LegacyAny) => {
          this.handleError(error?.error);
        });
      }),
      tap(() => this.setLoading(false)),
    );
  });

  /** Change to previous page. If we have links for previous data, issue api and then change to previous page */
  readonly prev = this.effect(($) => {
    return $.pipe(
      withLatestFrom(this.paginationState$),
      filter(([, p]) => p.hasPrev),
      tap(() => this.setLoading(true)),
      withLatestFrom(this.state$),
      switchMap(([, state]) => {
        // @ts-expect-error (legacy code incremental fix)
        const queries: HarvestDataPrevQuery[] = state.resources
          .map((r) => ({
            resourceType: r.resourceType,
            resourceId: r.resourceId,
            url: state.data[r.resourceId]?.prevLink?.url,
          }))
          .filter((v) => !!v.url);
        return from(this.callApis(queries));
      }),
      withLatestFrom(this.rawData$),
      tap(([{ data, errors }, currentData]) => {
        let changed = false;

        const newData = { ...currentData };
        Object.keys(data).forEach((resourceId) => {
          const responseValues = data[resourceId].values || [];
          changed = changed || responseValues.length > 0;
          const currentValues = currentData[resourceId]?.values || [];
          newData[resourceId] = {
            values: [...currentValues, ...responseValues],
            prevLink: data[resourceId]?.prevLink,
          };
        });
        if (changed) {
          this.patchState((state) => ({ data: newData }));
        }
        errors.forEach((error: LegacyAny) => {
          this.handleError(error.error);
        });
      }),
      tap(() =>
        this.patchState((s) => ({
          loading: false,
          currentPageIndex: s.currentPageIndex + 1,
        })),
      ),
    );
  });

  next = this.effect(($) =>
    $.pipe(
      withLatestFrom(this.paginationState$),
      filter(([, { hasNext }]) => hasNext),
      tap(([, { currentPageIndex }]) => {
        this.patchState({ currentPageIndex: currentPageIndex - 1 });
      }),
    ),
  );

  /* Utility Functions*/
  private callApis = (apiParams: Array<HarvestDataQuery | HarvestDataPrevQuery>): Promise<ApisResult> => {
    const promises = apiParams.map((param) => {
      const apiPromise = 'url' in param ? this.api.listPrevious(param) : this.api.list(param);

      return this.wrapApiResponse(apiPromise).then(
        (response: LegacyAny) =>
          ({
            ...response,
            resourceId: param.resourceId,
          }) as ApiResponseWithResource,
      );
    });

    return Promise.all(promises).then(convertResponsesData);
  };

  private wrapApiResponse = (p: Promise<ScRelation<HarvestData>>): Promise<ApiResponse> => {
    return Promise.resolve(p)
      .then((data) => ({ data }))
      .catch((error: LegacyAny) => ({ error }));
  };
}

type ApiResponse = { error: any } | { data: ScRelation<HarvestData> };

type WithResource = Pick<HarvestResource, 'resourceId'>;

type ApiResponseWithResource = ApiResponse & WithResource;

type ApisResult = {
  data: { [resourceId: string]: ResourceDataState };
  errors: Array<{ error: any } & WithResource>;
};

const convertResponsesData = (res: ApiResponseWithResource[]): ApisResult => {
  const data: LegacyAny = {};
  const errors: LegacyAny = [];
  res.forEach((r) => {
    if ('data' in r) {
      data[r.resourceId] = {
        values: r.data.data || [],
        prevLink: r.data.links.prev,
      } as ResourceDataState;
    } else if ('error' in r) {
      errors.push(r);
    }
  });
  return { data, errors };
};

export const generateSearchQueries = (
  resources: HarvestResource[],
  timeRange: TimeRange,
  itemsPerPage: number,
): HarvestDataQuery[] => {
  const { from, to } = resolveTimeRange(timeRange);
  // @ts-expect-error (legacy code incremental fix)
  return resources.map((r) => ({
    resourceType: r.resourceType,
    resourceId: r.resourceId,
    from,
    to,
    sort: 'desc',
    limit: itemsPerPage,
  }));
};

function removeVisibilityResourceLabels(visibilities: DataSeriesVisibility): DataSeriesVisibility {
  const result: DataSeriesVisibility = {};
  for (const key in visibilities) {
    const k = key.split(LABEL_RESOURCE_SEPARATOR)[0];
    result[k] = visibilities[key];
  }
  return result;
}
