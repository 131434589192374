import { LegacyAny } from '@soracom/shared/core';

// -----------------------------------------------------------------------------
// app.ts
//
// This is the main app file that declares the main app module (we probably
// won"t divide the app into multiple modules until we start hybridizing to
// Angular 5 (?)) and imports all the necessary code, including the legacy
// JavaScript code and related hacks.
// -----------------------------------------------------------------------------

// Enable to set window.app in typescript 2.

import { AppRootComponent } from './appRoot.component';

declare global {
  interface Window {
    app: LegacyAny;
    dayjs: LegacyAny;
    _: LegacyAny;
    $: LegacyAny;
    jQuery: LegacyAny;
    JSONEditor: LegacyAny;
    pleaseWait: LegacyAny;
    loadingScreenFinishing: boolean;
    loadingScreen: any;
    /** internal flag for debug AsyncOperationService  */
    soracom_user_console_async_operation_service_debug_values: LegacyAny;
  }
}

// This is simplest solution to load global libraries
// import * as jquery from "jquery";
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(timezone)

window.dayjs = dayjs;

// -----------------------------------------------------------------------------
// AngularJS→Angular stuff
//
// Imports related to adding modern Angular and hybridizing the app
// -----------------------------------------------------------------------------

import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';
import { Store } from '@ngrx/store';
import * as angular from 'angular';

/**
 * DO EARLY. This import has the side effect of setting `window.SoracomUserConsole = SoracomUserConsole.shared`That is how both legacy JS code and human operators (using the browser console) access the SoracomUserConsole singleton object. TypeScript code can just import it and reference `SoracomUserConsole.shared` but we make sure to import this (and thereby instantiate the singleton instance) as early as possible, for the benefit of the old code which just relies on a window global.
 */
import { SoracomUserConsole } from '../../../src/app/shared/SoracomUserConsole';

import { SupportLoginComponent } from '../../../src/app/auth/support-login/support-login.component';
import { SupportLogoutComponent } from '../../../src/app/auth/support-logout/support-logout.component';
import { FooterComponent } from '@soracom/shared-ng/ui-soracom';
import { FunkConfigEditorComponent } from '../../../src/app/funk-config-editor/funk-config-editor.component';
import { FunnelConfigComponent } from '../../../src/app/funnel-config/funnel-config.component';
import { GpsMultiunitComponent } from '../../../src/app/gps-multiunit/gps-multiunit/gps-multiunit.component';
import { MergeInvoicesComponent } from '../../../src/app/merge-invoices/merge-invoices.component';
import { PaymentInvoiceInfoComponent } from '../../../src/app/payment-invoice-info/payment-invoice-info.component';
import { PortMappingsApiService } from '../../../src/app/port-mappings-api.service';
import { MultiFactorAuthComponent } from '../../../src/app/security/multi-factor-auth/multi-factor-auth.component';
import { NavigationBeforeLoginComponent } from '../../../src/app/soracom-before-login/navigation-before-login/navigation-before-login.component';
import { SignupKddiFinishedComponent } from '../../../src/app/soracom-before-login/signup-kddi-finished/signup-kddi-finished.component';
import { UiImsiComponent } from '@soracom/shared-ng/ui-common-high-level';
import { UiPasswordConfirmComponent } from '../../../src/app/soracom-ui/ui-password-confirm/ui-password-confirm.component';
import { SearchComponent } from '../../../src/app/soracom-ui/ui-search/ui-search.component';
import { OnDemandRemoteAccessComponent } from '../../../src/app/subscribers/on-demand-remote-access/on-demand-remote-access.component';
import { SpeedClassLegendComponent } from '../../../src/app/subscribers/speed-class-legend/speed-class-legend.component';
import { VpgAllowedOperatorsComponent } from '../../../src/app/virtual-private-gateway/vpg-allowed-operators/vpg-allowed-operators.component';

// setAngularJSGlobal(angular);
//
// if (environment.production || environment.test) {
//   enableProdMode();
// }
//
// const bootstrapFn = (extraProviders: StaticProvider[]) => {
//   const platformRef = platformBrowserDynamic(extraProviders);
//   return platformRef.bootstrapModule(AppModule);
// };
//
// const downgradedModule = downgradeModule(bootstrapFn);

// -----------------------------------------------------------------------------
// Import JS libraries
//
// description forthcoming
// -----------------------------------------------------------------------------
import 'bootstrap';
import 'file-saver';

import 'angular-animate';
import 'angular-loading-bar';
import 'angular-route';
import 'angular-sanitize';
import 'angular-strap';
import 'angular-translate';
import 'angular-translate-handler-log';
import 'angular-translate-loader-static-files';
import 'angular-translate-storage-cookie';
import 'angular-translate-storage-local';
import 'angular-ui-ace';
import 'angular-ui-bootstrap';
import 'angular-visibility-change';
import 'ngclipboard';

// -----------------------------------------------------------------------------
// Import TypeScript code from monorepo libraries
//
// This is either new code, or code that was fully migrated to Angular strict mode, had any AngularJS dependencies removed (except special cases where compatibility is maintained).
// -----------------------------------------------------------------------------

// Obviously, this name is temporary 😇
import { ApiCredentialsService, AuthService as SexyDynamiteAuthService, CoverageTypeService, FeatureVisibilityService } from '@soracom/shared/data-access-auth';
import { LoginUserDataService } from '@soracom/shared/data-access-auth';
import { TokenExpirationWatcher } from '@soracom/shared/data-access-auth';

import { BreadcrumbService } from '@soracom/shared-ng/routing';

// -----------------------------------------------------------------------------
// Import TypeScript files
//
// Import TypeScript files so the classes/objects/functions they define can be
// registered as Angular entities
// -----------------------------------------------------------------------------
import { $locationShim } from '@angular/common/upgrade';
import { SoracomDateService } from '@soracom/shared-ng/date-service';
import { OperatorConfigurationService } from '@soracom/shared-ng/operator-configuration';
import { UserNotificationsComponent } from '@user-console/user-console-common';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';
import { AppComponent } from '../../../src/app/app.component';
import { AwsRegionAutocompleteComponent } from '@soracom/shared-ng/autocomplete';
import { BillingPageComponent } from '../../../src/app/billing/billing-page/billing-page.component';
import { EditEventHandlerComponent } from '../../../src/app/event-handler/edit-event-handler/edit-event-handler.component';
import { EventHandlerListComponent } from '../../../src/app/event-handler/event-handler-list/event-handler-list.component';
import { GroupChooserComponentAngularVersion } from '../../../src/app/group-chooser/group-chooser.component';
import { HarvestDataTableComponent } from '../../../src/app/harvest-data/harvest-data-table/harvest-data-table.component';
import { HarvestDataChartContainerComponent } from '../../../src/app/harvest-data/harvest-data-viz/viz-container/harvest-data-viz-container.component';
import { HarvestDataComponent as HarvestDataComponent2 } from '../../../src/app/harvest-data/harvest-data.component';
import { LagoonDashboardsComponent } from '../../../src/app/lagoon/lagoon-dashboards/lagoon-dashboards.component';
import { LagoonLicensePacksComponent } from '../../../src/app/lagoon/lagoon-license-packs/lagoon-license-packs.component';
import { LagoonLogoComponent } from '../../../src/app/lagoon/lagoon-logo/lagoon-logo.component';
import { LagoonPageComponent } from '../../../src/app/lagoon/lagoon-page/lagoon-page.component';
import { LagoonPlanComponent } from '../../../src/app/lagoon/lagoon-plan/lagoon-plan.component';
import { LagoonUsersComponent } from '../../../src/app/lagoon/lagoon-users/lagoon-users.component';
import { LagoonComponent } from '../../../src/app/lagoon/lagoon/lagoon.component';
import { LteMButtonComponent } from '../../../src/app/lte-m-button/lte-m-button/lte-m-button.component';
import { NewOrderComponent } from '../../../src/app/order/new-order/new-order.component';
import { MultiResourceSelectorComponent } from '../../../src/app/resource-selector/multi-resource-selector/multi-resource-selector.component';
import { AuthKeysComponent } from '../../../src/app/security/auth-keys/auth-keys.component';
import { CredentialsComponent as CredentialsComponentPage } from '../../../src/app/security/credentials/credentials.component';
import { DefaultSamPermissionConfigComponent } from '../../../src/app/security/default-sam-permission-config/default-sam-permission-config.component';
import { PermissionsEditorComponent } from '../../../src/app/security/permissions-editor/permissions-editor.component';
import { RoleDetailsComponent as RoleDetailsComponentPage } from '../../../src/app/security/roles/role-details/role-details.component';
import { RolesComponent as RolesComponentPage } from '../../../src/app/security/roles/roles.component';
import { UserMultiFactorAuthComponent } from '../../../src/app/security/sam-users/authentication/user-multi-factor-auth/user-multi-factor-auth.component';
import { SamUsersComponent } from '../../../src/app/security/sam-users/sam-users.component';
import { UserDetailsComponent as SamUserDetailsComponent } from '../../../src/app/security/sam-users/user-details/user-details.component';
import { UpdatePasswordComponent } from '../../../src/app/security/update-password/update-password.component';
import { UCStorage } from '../../../src/app/shared/UCStorage';
import { SimPacketCaptureSessionsComponent } from '../../../src/app/sim-packet-capture-sessions/sim-packet-capture-sessions.component';
import { SimsSearchBoxComponent } from '../../../src/app/sims/sims-search-box/sims-search-box.component';
import { EmailSettingsComponent } from '../../../src/app/soracom-account-management/email-settings/email-settings.component';
import { VerifyTokenComponent } from '../../../src/app/soracom-account-management/verify-token/verify-token.component';
import { AnnouncekitComponent } from '../../../src/app/soracom-before-login/announcekit/announcekit.component';
import { SignupKddiComponent } from '../../../src/app/soracom-before-login/signup-kddi/signup-kddi.component';
import { AirConfigComponent } from '../../../src/app/soracom-groups/air-config/air-config.component';
import { BeamConfigComponent } from '../../../src/app/soracom-groups/beam-config/beam-config.component';
import { EndorseConfigComponent } from '../../../src/app/soracom-groups/endorse-config/endorse-config.component';
import { HarvestDataConfigComponent } from '../../../src/app/soracom-groups/harvest-data-config/harvest-data-config.component';
import { HarvestFileConfigComponent } from '../../../src/app/soracom-groups/harvest-file-config/harvest-file-config.component';
import { KryptonConfigComponent } from '../../../src/app/soracom-groups/krypton-config/krypton-config.component';
import { OrbitConfigComponent } from '../../../src/app/soracom-groups/orbit-config/orbit-config.component';
import { GroupTableComponent } from '../../../src/app/soracom-groups/table/group-table.component';
import { UnifiedEndpointConfigComponent } from '../../../src/app/soracom-groups/unified-endpoint-config/unified-endpoint-config.component';
import { LogViewerComponent } from '../../../src/app/soracom-logs/log-viewer/log-viewer.component';
import { LongTermDiscountsComponent } from '../../../src/app/soracom-long-term-discounts/long-term-discounts/long-term-discounts.component';
import { OrderNotificationComponent } from '../../../src/app/soracom-navigation/order-notification/order-notification.component';
import { PaymentMethodService } from '../../../src/app/soracom-payment/payment-method-service.service';
import { PaymentMethodViewerComponent } from '../../../src/app/soracom-payment/payment-method-viewer/payment-method-viewer.component';
import { UiDatetimeRangeSelectorComponent } from '@soracom/shared-ng/ui-common';
import { RegisterSimComponent } from '../../../src/app/subscribers/register-sim/register-sim.component';
import { VsimDetailsComponent } from '../../../src/app/subscribers/vsim-details/vsim-details.component';
import { TrafficVolumeRankingChartComponent } from '../../../src/app/traffic-volume-ranking/traffic-volume-ranking-chart/traffic-volume-ranking-chart.component';
import { JunctionInspectionComponent } from '../../../src/app/virtual-private-gateway/junction-settings/junction-inspection/junction-inspection.component';
import { JunctionMirroringComponent } from '../../../src/app/virtual-private-gateway/junction-settings/junction-mirroring/junction-mirroring.component';
import { JunctionPeerEditorModalComponent } from '../../../src/app/virtual-private-gateway/junction-settings/junction-mirroring/junction-peer-editor-modal.component';
import { JunctionRedirectionComponent } from '../../../src/app/virtual-private-gateway/junction-settings/junction-redirection/junction-redirection.component';
import { VpgPacketCaptureSessionsComponent } from '../../../src/app/virtual-private-gateway/vpg-packet-capture-sessions/vpg-packet-capture-sessions.component';
import { VpgRoutingFilterComponent } from '../../../src/app/virtual-private-gateway/vpg-routing-filter/vpg-routing-filter.component';
import { environment } from '../../../src/environments/environment';
import { TrafficVolumeRankingComponent } from '../_traffic_volume_ranking/traffic_volume_ranking_component';
import { BaseComponent } from '../after_login/base.component';
import { AuditLogsComponent } from '../audit_logs/audit_logs.component';
import { AuditLogsPageComponent } from '../audit_logs/audit_logs_page.component';
import { NapterAuditLogsService } from '../audit_logs/napter_audit_logs.service';
import { BillingsService } from '../billings/billings.service';
import { ButtonsService } from '../buttons/buttons.service';
import { AlertsDirective } from '../components/alerts.directive';
import { AlertsService } from '../components/alerts.service';
import { AuthService } from '../components/auth.service';
import { BaseTagListComponent } from '../components/base_tag_list.component';
import { CapitalizeDirective } from '../components/capitalize.directive';
import { ScCapitalizeFilter } from '../components/capitalize.filter';
import { ConfirmDirective } from '../components/confirm.directive';
import { ContextMenuDirective } from '../components/context_menu.directive';
import { ContextMenuService } from '../components/context_menu.service';
import { CountryService } from '../components/country.service';
import { DateTimePickerComponent } from '../components/date_time_picker.component';
import { FeatureVisibilityComponent } from '../components/feature_visibility.component';
import {
  FeatureVisibilityDirective,
  FeatureVisibilityInverseDirective
} from '../components/feature_visibility.directive';
import { FilterReservedTagFilter } from '../components/filter_reserved_tag.filter';
import { FocusMeDirective } from '../components/focus_me.directive';
import { FormGroupValidityClassesDirective } from '../components/form_group_validity_classes.directive';
import { GenericButtonBarComponent } from '../components/generic_button_bar.component';
import { GroupChooserComponent } from '../components/group_chooser.component';
import { InplaceEditableTextComponent } from '../components/inplace_editable_text.component';
import { ItemChooserComponent } from '../components/item_chooser.component';
import { KeyEventService } from '../components/key_event_service';
import { ChangeTerminationProtectionComponent } from '../components/modals/change_termination_protection.component';
import { NgEnterDirective } from '../components/ng_enter.directive';
import { OperatorIdDirective } from '../components/operator_id.directive';
import { OrderGroupByNameFilter } from '../components/order_group_by_name.filter';
import { OrderObjectByFilter } from '../components/order_object_by.filter';
import { OrderReservedTagFilter } from '../components/order_reserved_tag.filter';
import { RedirectComponent } from '../components/redirect.component';
import { ReverseFilter } from '../components/reverse.filter';
import { ScDateTimeDirective } from '../components/sc_date_time.directive';
import { ScModalDefaultActionDirective } from '../components/sc_modal_default_action.directive';
import { ScOnReturnDirective } from '../components/sc_on_return.directive';
import { ScPaginationComponent } from '../components/sc_pagination.component';
import { SendDownlinkMessageComponent } from '../components/send_downlink_message.component';
import { SessionStatusLabelComponent } from '../components/session-status-label/session_status_label.component';
import { SoracomApiService } from '../components/soracom_api.service';
import { SortFilterMenuComponent } from '../components/sort_filter_menu.component';
import { TableColumnOptionsService } from '../../../src/app/ng-rewrites/legacy-table/table_column_options.service';
import { TermsOfServiceAgreementComponent } from '../components/terms_of_service_agreement.component';
import { TimestampInjectorService } from '../components/timestamp_injector.service';
import { TooltipComponent } from '../components/tooltip.component';
import { TutorialComponent } from '../components/tutorial.component';
import { UpdateFeedbackDirective } from '../components/update_feedback.directive';
import { CompanyInformationService } from '../contact_information/company_information.service';
import { IndividualInformationService } from '../contact_information/individual_information.service';
import { PostalAddressService } from '../contact_information/postal_address.service';
import { LegacyBaseSoracomApiService, PaginationService } from '@user-console/legacy-soracom-api-client';

import { OperatorConfigurationNamespaceService } from '@soracom/shared-ng/operator-configuration';
import { TranslateLookupErrorHandlerFactory } from '../core/translate_lookup_error_handler';
import { DebugComponent } from '../debug/debug.component';
import { DeviceObjectModelsComponent } from '../device_object_models/device_object_models.component';
import { DeviceObjectModelsService } from '../device_object_models/device_object_models.service';
import { AddDeviceObjectModelModalComponent } from '../device_object_models/modals/add_device_object_model_modal.component';
import { DeleteDeviceObjectModelModalComponent } from '../device_object_models/modals/delete_device_object_model_modal.component';
import { DeviceStatusLabelComponent } from '../devices/device-status-label/device_status_label.component';
import { DeviceComponent } from '../devices/device.component';
import { DeviceKeysComponent } from '../devices/device_keys.component';
import { DevicesComponent } from '../devices/devices.component';
import { DevicesService } from '../devices/devices.service';
import { AddDeviceKeyModalComponent } from '../devices/modals/add_device_key_modal.component';
import { ChangeDeviceStatusModalComponent } from '../devices/modals/change_device_status_modal.component';
import { EditDeviceResourceModalComponent } from '../devices/modals/edit_device_resource.component';
import { ExecuteDeviceResourceModalComponent } from '../devices/modals/execute_device_resource_modal.component';
import { EventHandlersService } from '../event_handlers/event_handlers.service';
import { GpsMultiunitPageComponent } from '../gadgets/gps_multiunit_page.component';
import { LteMButtonPageComponent } from '../gadgets/lte_m_button_page/lte_m_button_page.component';
import { GroupDetailsComponent } from '../groups/group_details.component';
import { GroupsComponent } from '../groups/groups.component';
import { GroupsService } from '../groups/groups.service';
import { GroupsCacheService } from '../groups/groups_cache.service';
import { HarvestDataComponent } from '../harvest_data/harvest_data.component';
import { HarvestDataService } from '../harvest_data/harvest_data.service';
import { HarvestFilesService } from '../harvest_files/harvest_files.service';
import { HarvestFilesPageComponent } from '../harvest_files/harvest_files_page.component';
import { UploadHarvestFileModalComponent } from '../harvest_files/modals/upload_harvest_file.modal.component';
import { LagoonService } from '../lagoon/lagoon.service';
import { LagoonDashboardsService } from '../lagoon/lagoon_dashboards.service';
import { LagoonUsersService } from '../lagoon/lagoon_users.service';
import { LogsComponent } from '../logs/logs.component';
import { LogsService } from '../logs/logs.service';
import { LongTermDiscountsPageComponent } from '../long_term_discounts/long_term_discounts_page.component';
import { LoraDeviceStatusLabelComponent } from '../lora_devices/lora-device-status-label/lora_device_status_label.component';
import { LoraDevicesComponent } from '../lora_devices/lora_devices.component';
import { LoraDevicesService } from '../lora_devices/lora_devices.service';
import { LoraGatewayStatusLabelComponent } from '../lora_gateways/lora-gateway-status-label/lora_gateway_status_label.component';
import { LoraGatewayComponent } from '../lora_gateways/lora_gateway.component';
import { LoraGatewaysComponent } from '../lora_gateways/lora_gateways.component';
import { LoraGatewaysService } from '../lora_gateways/lora_gateways.service';
import { LoraGatewaysTableComponent } from '../lora_gateways/lora_gateways_table.component';
import { LoraNetworkSetComponent } from '../lora_network_sets/lora_network_set.component';
import { LoraNetworkSetsComponent } from '../lora_network_sets/lora_network_sets.component';
import { LoraNetworkSetsService } from '../lora_network_sets/lora_network_sets.service';
import { AddLoraNetworkSetModalComponent } from '../lora_network_sets/modals/lora_network_set_add.modal.component';
import { HarvestContractComponent } from '../operators/harvest_contract.component';
import { UpdateContractModalComponent } from '../operators/modals/update_contract.modal.component';
import { OperatorContractsService } from '../operators/operator_contracts.service';
import { OrdersService } from '../orders/orders.service';
import { CredentialsSetsService } from '../security/credentials_sets.service';
import { DefaultPermissionsService } from '../security/default_permissions.service';
import { RoleAddModalComponent } from '../security/modals/role_add.modal.component';
import { RolesService } from '../security/modals/roles.service';
import { UserRolesService } from '../security/user_roles.service';
import { UsersService } from '../security/users.service';
import { AddressService } from '../shipping_addresses/address.service';
import { ShippingAddressesService } from '../shipping_addresses/shipping_addresses.service';
import { RegisterSigfoxDeviceComponent } from '../sigfox_devices/modals/register_sigfox_device.component';
import { SigfoxShowPacComponent } from '../sigfox_devices/modals/sigfox-show-pac.component';
import { SigfoxDeviceStatusLabelComponent } from '../sigfox_devices/sigfox-device-status-label/sigfox_device_status_label.component';
import { SigfoxDevicesComponent } from '../sigfox_devices/sigfox_devices.component';
import { SigfoxDevicesService } from '../sigfox_devices/sigfox_devices.service';
import { SoraCamCellularPacksService } from '../sora_cam/sora_cam_cellular_pack.service';
import { SoraletLogsService } from '../soralets/soralet_logs.service';
import { SoraletVersionsService } from '../soralets/soralet_versions.service';
import { SoraletsService } from '../soralets/soralets.service';
import { GroupSubscribersTableComponent } from '../subscribers/group_subscribers_table.component';
import { NgLocationService } from '../subscribers/ng_location.service';
import { SimsComponent } from '../subscribers/sims.component';
import { SimsService } from '../subscribers/sims.service';
import { SubscriberStatusLabelComponent } from '../subscribers/subscriber-status-label/subscriber_status_label.component';
import { SubscriberSearchStatusFactory } from '../subscribers/subscriber_search_status';
import { SubscriberSessionCountryInfoComponent } from '../subscribers/subscriber_session_country_info.component';
import { SubscribersComponent } from '../subscribers/subscribers.component';
import { SubscribersService } from '../subscribers/subscribers.service';
import { SubscriberSearchBoxComponent } from '../subscribers/subscribers_search_box.component';
import { SubscriberEnumeratorsFactory } from '../subscribers/subscriber_enumerators.factory';
import { VirtualPrivateGatewaysService } from '../virtual_private_gateways/virtual_private_gateways.service';
import { routes } from './app.route';
import { NavigationInterceptionService } from './navigation_interception.service';
import { initLocaleService } from '@soracom/shared/locale-service';

import { PrefixedEventHandlersComponent } from '@user-console/event-handler';

// Initialize here to make it before angular.js config
// TODO
initLocaleService();

import '@soracom/shared/sim-status-info';
import { HeaderComponent } from '@soracom/shared-ng/header';

window.app = angular.module('SoracomUserConsole', [
  'angular-loading-bar',
  'mgcrea.ngStrap.navbar',
  'mgcrea.ngStrap.helpers.dateFormatter',
  'mgcrea.ngStrap.helpers.dateParser',
  'mgcrea.ngStrap.helpers.dimensions',
  'mgcrea.ngStrap.tooltip',
  'mgcrea.ngStrap.datepicker',
  'mgcrea.ngStrap.timepicker',
  'ngAnimate',
  'ngclipboard',
  'ngRoute',
  'ngSanitize',
  'pascalprecht.translate', // angular-translate
  'ui.ace',
  'ui.bootstrap',
  'visibilityChange',
  // downgradedModule
]);

export const AppName = 'SoracomUserConsole';
angular
  .module(AppName)
  // --------------------------------------------------------------------------
  // .config() comes first
  // --------------------------------------------------------------------------
  .config([
    'cfpLoadingBarProvider',
    function (cfpLoadingBarProvider: LegacyAny) {
      cfpLoadingBarProvider.includeSpinner = false;
    },
  ])
  .config([
    '$httpProvider',
    '$sceDelegateProvider',
    ($httpProvider: LegacyAny, $sceDelegateProvider: LegacyAny) => {
      // This was in soracom_api_service.js before it was converted to TypeScript
      $httpProvider.defaults.useXDomain = true;
      $sceDelegateProvider.trustedResourceUrlList([
        'self',
        `${environment.apiUrl.jp}/**`,
        `${environment.apiUrl.g}/**`,
      ]);
    },
  ])
  .config([
    '$httpProvider',
    ($httpProvider: ng.IHttpProvider) => {
      $httpProvider.interceptors.push('TimestampInjectorService');
    },
  ])
  .config([
    '$logProvider',
    ($logProvider: LegacyAny) => {
      $logProvider.debugEnabled(environment.logEnabled);
    },
  ])
  .config([
    '$translateProvider',
    ($translateProvider: LegacyAny) => {
      $translateProvider.translations('en', SoracomUserConsole.localeEn);
      $translateProvider.translations('ja', SoracomUserConsole.localeJa);
      $translateProvider.registerAvailableLanguageKeys(['en', 'ja'], {
        'en_*': 'en',
        'ja_*': 'ja',
        '*': 'en',
      });
      $translateProvider.fallbackLanguage('en');
      $translateProvider.useMissingTranslationHandler('translateLookupErrorHandlerFactory');
      $translateProvider.useSanitizeValueStrategy('escaped');
    },
  ])
  // Mason 2022-03-04: It was previously true that we could not use Angular services (e.g., JwtService) at this point, because Angular was not yet loaded. However, since the change in 2022 to load Angular first (finally!) this is no longer true. It may not be necessary here, but now we CAN use Angular services here.
  .config(routes)
  .run([
    '$location',
    '$log',
    '$rootScope',
    '$route',
    'AuthService',
    'FeatureVisibilityService',
    'BreadcrumbService',
    (
      $location: LegacyAny, // FIXME: TONS OF UNUSED HERE
      $log: LegacyAny,
      $rootScope: LegacyAny,
      $route: LegacyAny,
      authService: AuthService,
      featureVisibilityService: FeatureVisibilityService,
      breadcrumbService: BreadcrumbService
    ) => {
      // mason 2023-06-30: There are several unused parameters above, that I considered removing, but the app's interactions with legacy hybrid app AngularJS stuff like AuthService might be sensitive to instantiation timing. If we remove these params here, I confirmed that the services referenced will be instantiated at a later, unpredictable time. So I believe we should leave these params here, even though they are unused, until AuthService and FeatureVisibilityService are actually completely deleted (Core Services Phase 3).

      // Mason 2021-11-11: If you add things to this list of default values, please also add them to login() in cypress/util/login.ts so that the simulated login scenario keeps the same default values.
      if (UCStorage.loginAsRoot == null) {
        UCStorage.loginAsRoot = true;
      }
      if (UCStorage.itemsPerPage == null) {
        UCStorage.itemsPerPage = {};
      }

      // We need to give the Angular breadcrumb service a ref to $rootScope to let it also watch AngularJS routes:
      breadcrumbService.legacyAngularJs$rootScope = $rootScope;
    },
  ])
  .component('appRoot', new AppRootComponent())
  // .controller('AppCtrl', AppController)
  // --------------------------------------------------------------------------
  // .component()
  // --------------------------------------------------------------------------
  .component('scAddDeviceObjectModelModalComponent', new AddDeviceObjectModelModalComponent())
  .component('scAddDeviceKeyModalComponent', new AddDeviceKeyModalComponent())
  .component('scAuditLogs', new AuditLogsComponent())
  .component('scBase', new BaseComponent())
  .component('scChangeDeviceStatusModalComponent', new ChangeDeviceStatusModalComponent())
  .component('scChangeTerminationProtectionComponent', new ChangeTerminationProtectionComponent())
  .component('scDateTimePicker', new DateTimePickerComponent())
  .component('scDebug', new DebugComponent())
  .component('scDeleteDeviceObjectModelModalComponent', new DeleteDeviceObjectModelModalComponent())
  .component('scDevice', new DeviceComponent())
  .component('scDeviceKeys', new DeviceKeysComponent())
  .component('scDeviceObjectModels', new DeviceObjectModelsComponent())
  .component('scDeviceStatusLabel', new DeviceStatusLabelComponent())
  .component('scDevices', new DevicesComponent())
  .component('scEditDeviceResourceModalComponent', new EditDeviceResourceModalComponent())
  .component('scExecuteDeviceResourceModalComponent', new ExecuteDeviceResourceModalComponent())
  .component('scGenericButtonBar', new GenericButtonBarComponent())
  .component('scGpsMultiunitPage', new GpsMultiunitPageComponent())
  .component('scGroupChooserComponent', new GroupChooserComponent())
  .component('scGroups', new GroupsComponent())
  .component('scGroupDetails', new GroupDetailsComponent())
  .component('scHarvestContract', new HarvestContractComponent())
  .component('scHarvestData', new HarvestDataComponent())
  .component('scHarvestFiles', new HarvestFilesPageComponent())
  .component('scItemChooser', new ItemChooserComponent())
  .component('scLogs', new LogsComponent())
  .component('scLongTermDiscounts', new LongTermDiscountsPageComponent())
  .component('scLoraDevices', new LoraDevicesComponent())
  .component('scLoraDeviceStatusLabel', new LoraDeviceStatusLabelComponent())
  .component('scLoraGateway', new LoraGatewayComponent())
  .component('scLoraGateways', new LoraGatewaysComponent())
  .component('scLoraGatewaysTable', new LoraGatewaysTableComponent())
  .component('scLoraGatewayStatusLabel', new LoraGatewayStatusLabelComponent())
  .component('scLoraNetworkSet', new LoraNetworkSetComponent())
  .component('scLoraNetworkSets', new LoraNetworkSetsComponent())
  .component('scLteMButtonPage', new LteMButtonPageComponent())
  .component('scAddLoraNetworkSetModalComponent', new AddLoraNetworkSetModalComponent())
  .component('scNotAllowed', new FeatureVisibilityComponent())
  .component('scAuditLogsPage', new AuditLogsPageComponent())
  .component('scPagination', new ScPaginationComponent())
  .component('scRedirect', new RedirectComponent())
  .component('scRegisterSigfoxDeviceComponent', new RegisterSigfoxDeviceComponent())
  .component('scSendDownlinkMessageComponent', new SendDownlinkMessageComponent())
  .component('scSessionStatusLabel', new SessionStatusLabelComponent())
  .component('scSigfoxDevices', new SigfoxDevicesComponent())
  .component('scSigfoxShowPac', new SigfoxShowPacComponent())
  .component('scSigfoxDeviceStatusLabel', new SigfoxDeviceStatusLabelComponent())
  .component('scSims', new SimsComponent())
  .component('scSortFilterMenu', new SortFilterMenuComponent())
  .component('scSubscriberSearchBox', new SubscriberSearchBoxComponent())
  .component('scSubscriberSessionCountryInfo', new SubscriberSessionCountryInfoComponent())
  .component('scSubscriberStatusLabel', new SubscriberStatusLabelComponent())
  .component('scSubscribers', new SubscribersComponent())
  .component('scGroupSubscribersTable', new GroupSubscribersTableComponent())
  .component('scTagList', new BaseTagListComponent())
  .component('scTermsOfServiceAgreement', new TermsOfServiceAgreementComponent())
  .component('scTooltip', new TooltipComponent())
  .component('scTrafficVolumeRanking', new TrafficVolumeRankingComponent())
  .component('scTutorialComponent', new TutorialComponent())
  .component('scUpdateContractModalComponent', new UpdateContractModalComponent())
  .component('scUploadHarvestFileModalComponent', new UploadHarvestFileModalComponent())
  .component('scRoleAddModalComponent', new RoleAddModalComponent())
  .component('scInplaceEditableText', new InplaceEditableTextComponent())
  // --------------------------------------------------------------------------
  // .directive()
  // --------------------------------------------------------------------------
  .directive('focusMe', FocusMeDirective)
  .directive('ngEnter', NgEnterDirective)
  .directive('scAlerts', AlertsDirective.Factory())
  .directive('scCapitalize', CapitalizeDirective)
  .directive('scConfirm', ConfirmDirective)
  .directive('scContextMenu', ContextMenuDirective)
  .directive('scDateTime', ScDateTimeDirective)
  .directive('scFeature', FeatureVisibilityDirective)
  .directive('scFormGroupValidityClasses', FormGroupValidityClassesDirective)
  .directive('scModalDefaultAction', ScModalDefaultActionDirective)
  .directive('scNoFeature', FeatureVisibilityInverseDirective)
  .directive('scOnReturn', ScOnReturnDirective)
  .directive('scOperatorId', OperatorIdDirective)
  .directive('scUpdateFeedback', UpdateFeedbackDirective)
  // --------------------------------------------------------------------------
  // .factory() (Includes downgraded Angular services)
  // --------------------------------------------------------------------------
  .factory('translateLookupErrorHandlerFactory', ['$log', TranslateLookupErrorHandlerFactory])
  .factory('BaseSoracomApiService', downgradeInjectable(LegacyBaseSoracomApiService))
  .factory('SoracomApi', downgradeInjectable(SoracomApiService))
  .factory('PaymentMethodService', downgradeInjectable(PaymentMethodService))
  .factory('PortMappingsApiService', downgradeInjectable(PortMappingsApiService))
  .factory('ngrxStoreService', downgradeInjectable(Store))
  .factory('TimestampInjectorService', TimestampInjectorService)
  .factory('BreadcrumbService', downgradeInjectable(BreadcrumbService))
  .factory('UiDsModalService', downgradeInjectable(UiDsModalService))
  .factory('$location', downgradeInjectable($locationShim))
  .factory('OperatorConfigurationService', downgradeInjectable(OperatorConfigurationService))
  .factory('SoracomDateService', downgradeInjectable(SoracomDateService))
  .factory('SexyDynamiteAuthService', downgradeInjectable(SexyDynamiteAuthService))
  .factory('LoginUserDataService', downgradeInjectable(LoginUserDataService))
  .factory('ApiCredentialsService', downgradeInjectable(ApiCredentialsService))
  .factory('TokenExpirationWatcher', downgradeInjectable(TokenExpirationWatcher))
  .factory('FeatureVisibilityService', downgradeInjectable(FeatureVisibilityService))
  .factory('CoverageTypeService', downgradeInjectable(CoverageTypeService))
  .factory('OperatorConfigurationNamespaceService', downgradeInjectable(OperatorConfigurationNamespaceService))
  .factory('TableColumnOptionsService', downgradeInjectable(TableColumnOptionsService))

  // --------------------------------------------------------------------------
  // .filter()
  // --------------------------------------------------------------------------
  .filter('capitalize', ScCapitalizeFilter)
  .filter('filterReservedTag', FilterReservedTagFilter)
  .filter('orderGroupByName', OrderGroupByNameFilter)
  .filter('orderObjectBy', OrderObjectByFilter)
  .filter('orderReservedTag', OrderReservedTagFilter)
  .filter('reverse', ReverseFilter)

  // --------------------------------------------------------------------------
  // .service()
  // --------------------------------------------------------------------------
  .service('AddressService', AddressService)
  .service('AlertsService', AlertsService)
  .service('AuthService', AuthService)
  .service('BillingsService', BillingsService)
  .service('ButtonsService', ButtonsService)
  .service('ContextMenuService', ContextMenuService)
  .service('CountryService', CountryService)
  .service('CredentialsSetsService', CredentialsSetsService)
  .service('DeviceObjectModelsService', DeviceObjectModelsService)
  .service('DevicesService', DevicesService)
  .service('EventHandler', EventHandlersService)
  .service('GroupsService', GroupsService)
  .service('GroupsCacheService', GroupsCacheService)
  .service('HarvestDataService', HarvestDataService)
  .service('HarvestFilesService', HarvestFilesService)
  .service('KeyEventService', KeyEventService)
  .service('LagoonDashboardsService', LagoonDashboardsService)
  .service('LagoonService', LagoonService)
  .service('LagoonUsersService', LagoonUsersService)
  .service('LogsService', LogsService)
  .service('LoraDevicesService', LoraDevicesService)
  .service('LoraGatewaysService', LoraGatewaysService)
  .service('LoraNetworkSetsService', LoraNetworkSetsService)
  .service('NapterAuditLogsService', NapterAuditLogsService)
  .service('NgLocationService', NgLocationService)
  .service('OrdersService', OrdersService)
  .service('RolesService', RolesService)
  .service('ShippingAddressesService', ShippingAddressesService)
  .service('SoraCamCellularPacksService', SoraCamCellularPacksService)
  .service('SoraletsService', SoraletsService)
  .service('SoraletLogsService', SoraletLogsService)
  .service('SoraletVersionsService', SoraletVersionsService)
  .service('DefaultPermissionsService', DefaultPermissionsService)
  .service('NavigationInterceptionService', NavigationInterceptionService)
  .service('OperatorContractsService', OperatorContractsService)
  .service('CompanyInformationService', CompanyInformationService)
  .service('IndividualInformationService', IndividualInformationService)
  .service('PaginationService', PaginationService)
  .service('PostalAddressService', PostalAddressService)
  .service('SigfoxDevicesService', SigfoxDevicesService)
  .service('SimsService', SimsService)
  .service('SubscriberSearchStatusFactory', SubscriberSearchStatusFactory)
  .service('SubscribersService', SubscribersService)
  .service('SubscriberEnumeratorsFactory', SubscriberEnumeratorsFactory)
  .service('UsersService', UsersService)
  .service('UserRolesService', UserRolesService)
  .service('VirtualPrivateGatewaysService', VirtualPrivateGatewaysService)
  .service('LoraNetworkSetsService', LoraNetworkSetsService)
  // --------------------------------------------------------------------------
  // AngularJS<->Angular
  // --------------------------------------------------------------------------
  .directive('appAnnouncekit', downgradeComponent({ component: AnnouncekitComponent }) as angular.IDirectiveFactory)
  .directive('appHeader', downgradeComponent({ component: HeaderComponent }) as angular.IDirectiveFactory)
  .directive(
    'appAwsRegionAutocomplete',
    downgradeComponent({ component: AwsRegionAutocompleteComponent }) as angular.IDirectiveFactory
  )
  .directive('appGpsMultiunit', downgradeComponent({ component: GpsMultiunitComponent }) as angular.IDirectiveFactory)
  .directive('appLteMButton', downgradeComponent({ component: LteMButtonComponent }) as angular.IDirectiveFactory)
  .directive(
    'appEventHandlerList',
    downgradeComponent({ component: EventHandlerListComponent }) as angular.IDirectiveFactory
  )
  .directive(
    'appEditEventHandler',
    downgradeComponent({ component: EditEventHandlerComponent }) as angular.IDirectiveFactory
  )
  .directive('appFooter', downgradeComponent({ component: FooterComponent }) as angular.IDirectiveFactory)
  .directive('appAirConfig', downgradeComponent({ component: AirConfigComponent }) as angular.IDirectiveFactory)
  .directive('appBeamConfig', downgradeComponent({ component: BeamConfigComponent }) as angular.IDirectiveFactory)
  .directive(
    'appFunkConfigEditor',
    downgradeComponent({ component: FunkConfigEditorComponent }) as angular.IDirectiveFactory
  )
  .directive('appFunnelConfig', downgradeComponent({ component: FunnelConfigComponent }) as angular.IDirectiveFactory)
  .directive(
    'appHarvestDataConfig',
    downgradeComponent({ component: HarvestDataConfigComponent }) as angular.IDirectiveFactory
  )
  .directive('appKryptonConfig', downgradeComponent({ component: KryptonConfigComponent }) as angular.IDirectiveFactory)
  .directive(
    'appUnifiedEndpointConfig',
    downgradeComponent({ component: UnifiedEndpointConfigComponent }) as angular.IDirectiveFactory
  )
  .directive('appEndorseConfig', downgradeComponent({ component: EndorseConfigComponent }) as angular.IDirectiveFactory)
  .directive(
    'appHarvestFileConfig',
    downgradeComponent({ component: HarvestFileConfigComponent }) as angular.IDirectiveFactory
  )
  .directive('appOrbitConfig', downgradeComponent({ component: OrbitConfigComponent }) as angular.IDirectiveFactory)
  .directive(
    'appGroupChooser',
    downgradeComponent({ component: GroupChooserComponentAngularVersion }) as angular.IDirectiveFactory
  )
  .directive(
    'appLongTermDiscounts',
    downgradeComponent({ component: LongTermDiscountsComponent }) as angular.IDirectiveFactory
  )
  .directive(
    'appNavigationBeforeLogin',
    downgradeComponent({ component: NavigationBeforeLoginComponent }) as angular.IDirectiveFactory
  )
  .directive(
    'appOnDemandRemoteAccessComponent',
    downgradeComponent({ component: OnDemandRemoteAccessComponent }) as angular.IDirectiveFactory
  )
  .directive(
    'appOrderNotification',
    downgradeComponent({ component: OrderNotificationComponent }) as angular.IDirectiveFactory
  )
  .directive('appBilling', downgradeComponent({ component: BillingPageComponent }) as angular.IDirectiveFactory)
  .directive(
    'appPaymentInvoiceInfo',
    downgradeComponent({ component: PaymentInvoiceInfoComponent }) as angular.IDirectiveFactory
  )
  .directive('appMergeInvoices', downgradeComponent({ component: MergeInvoicesComponent }) as angular.IDirectiveFactory)
  .directive('appLagoon', downgradeComponent({ component: LagoonComponent }) as angular.IDirectiveFactory)
  .directive('appLagoonLogo', downgradeComponent({ component: LagoonLogoComponent }) as angular.IDirectiveFactory)
  .directive(
    'appLagoonLicensePacks',
    downgradeComponent({ component: LagoonLicensePacksComponent }) as angular.IDirectiveFactory
  )
  .directive('appLagoonPage', downgradeComponent({ component: LagoonPageComponent }) as angular.IDirectiveFactory)
  .directive('appLagoonPlan', downgradeComponent({ component: LagoonPlanComponent }) as angular.IDirectiveFactory)
  .directive('appLagoonUsers', downgradeComponent({ component: LagoonUsersComponent }) as angular.IDirectiveFactory)
  .directive(
    'appLagoonDashboards',
    downgradeComponent({ component: LagoonDashboardsComponent }) as angular.IDirectiveFactory
  )
  .directive(
    'appMultiFactorAuth',
    downgradeComponent({ component: MultiFactorAuthComponent }) as angular.IDirectiveFactory
  )
  .directive('appNewOrder', downgradeComponent({ component: NewOrderComponent }) as angular.IDirectiveFactory)
  .directive('appSignupKddi', downgradeComponent({ component: SignupKddiComponent }) as angular.IDirectiveFactory)
  .directive(
    'appSignupKddiFinished',
    downgradeComponent({ component: SignupKddiFinishedComponent }) as angular.IDirectiveFactory
  )
  .directive(
    'appSpeedClassLegend',
    downgradeComponent({ component: SpeedClassLegendComponent }) as angular.IDirectiveFactory
  )
  .directive(
    'appSimPacketCaptureSessions',
    downgradeComponent({ component: SimPacketCaptureSessionsComponent }) as angular.IDirectiveFactory
  )
  .directive(
    'appPaymentMethodViewer',
    downgradeComponent({ component: PaymentMethodViewerComponent }) as angular.IDirectiveFactory
  )
  .directive(
    'appPermissionsEditor',
    downgradeComponent({ component: PermissionsEditorComponent }) as angular.IDirectiveFactory
  )
  .directive(
    'appUpdatePassword',
    downgradeComponent({ component: UpdatePasswordComponent }) as angular.IDirectiveFactory
  )
  .directive(
    'appDefaultSamPermissionConfig',
    downgradeComponent({ component: DefaultSamPermissionConfigComponent }) as angular.IDirectiveFactory
  )
  .directive('appSamUsers', downgradeComponent({ component: SamUsersComponent }) as angular.IDirectiveFactory)
  .directive('appAuthKeys', downgradeComponent({ component: AuthKeysComponent }) as angular.IDirectiveFactory)
  .directive('appUserDetails', downgradeComponent({ component: SamUserDetailsComponent }) as angular.IDirectiveFactory)
  .directive(
    'appUserMultiFactorAuth',
    downgradeComponent({ component: UserMultiFactorAuthComponent }) as angular.IDirectiveFactory
  )
  .directive('appRoles', downgradeComponent({ component: RolesComponentPage }) as angular.IDirectiveFactory)
  .directive('appCredentials', downgradeComponent({ component: CredentialsComponentPage }) as angular.IDirectiveFactory)
  .directive('appRoleDetails', downgradeComponent({ component: RoleDetailsComponentPage }) as angular.IDirectiveFactory)
  .directive('appSimsSearchBox', downgradeComponent({ component: SimsSearchBoxComponent }) as angular.IDirectiveFactory)
  .directive('appSupportLogin', downgradeComponent({ component: SupportLoginComponent }) as angular.IDirectiveFactory)
  .directive('appSupportLogout', downgradeComponent({ component: SupportLogoutComponent }) as angular.IDirectiveFactory)
  .directive('appRegisterSim', downgradeComponent({ component: RegisterSimComponent }) as angular.IDirectiveFactory)
  .directive(
    'appVpgAllowedOperators',
    downgradeComponent({ component: VpgAllowedOperatorsComponent }) as angular.IDirectiveFactory
  )
  .directive(
    'appVpgRoutingFilter',
    downgradeComponent({ component: VpgRoutingFilterComponent }) as angular.IDirectiveFactory
  )
  .directive('uiImsi', downgradeComponent({ component: UiImsiComponent }) as angular.IDirectiveFactory)
  .directive(
    'uiPasswordConfirm',
    downgradeComponent({ component: UiPasswordConfirmComponent }) as angular.IDirectiveFactory
  )
  .directive('uiSearch', downgradeComponent({ component: SearchComponent }) as angular.IDirectiveFactory)
  .directive(
    'appVpgPacketCaptureSessions',
    downgradeComponent({ component: VpgPacketCaptureSessionsComponent }) as angular.IDirectiveFactory
  )
  .directive('appEmailSettings', downgradeComponent({ component: EmailSettingsComponent }) as angular.IDirectiveFactory)
  .directive('appVerifyToken', downgradeComponent({ component: VerifyTokenComponent }) as angular.IDirectiveFactory)
  .directive(
    'appJunctionRedirection',
    downgradeComponent({ component: JunctionRedirectionComponent }) as angular.IDirectiveFactory
  )
  .directive(
    'appJunctionInspection',
    downgradeComponent({ component: JunctionInspectionComponent }) as angular.IDirectiveFactory
  )
  .directive(
    'appJunctionMirroring',
    downgradeComponent({ component: JunctionMirroringComponent }) as angular.IDirectiveFactory
  )
  .directive(
    'appJunctionPeerEditorModal',
    downgradeComponent({ component: JunctionPeerEditorModalComponent }) as angular.IDirectiveFactory
  )
  .directive('appLogViewer', downgradeComponent({ component: LogViewerComponent }) as angular.IDirectiveFactory)
  .directive(
    'appHarvestDataVizContainer',
    downgradeComponent({ component: HarvestDataChartContainerComponent }) as angular.IDirectiveFactory
  )
  .directive('appVsimDetails', downgradeComponent({ component: VsimDetailsComponent }) as angular.IDirectiveFactory)
  .directive(
    'uiDatetimeRangeSelector',
    downgradeComponent({ component: UiDatetimeRangeSelectorComponent }) as angular.IDirectiveFactory
  )
  .directive(
    'appMultiResourceSelector',
    downgradeComponent({ component: MultiResourceSelectorComponent }) as angular.IDirectiveFactory
  )
  .directive(
    'appHarvestDataTable',
    downgradeComponent({ component: HarvestDataTableComponent }) as angular.IDirectiveFactory
  )
  .directive('appHarvestData', downgradeComponent({ component: HarvestDataComponent2 }) as angular.IDirectiveFactory)
  .directive(
    'appTrafficVolumeRankingChart',
    downgradeComponent({ component: TrafficVolumeRankingChartComponent }) as angular.IDirectiveFactory
  )
  .directive('appGroupTable', downgradeComponent({ component: GroupTableComponent }) as angular.IDirectiveFactory)
  .directive('appMain', downgradeComponent({ component: AppComponent }) as angular.IDirectiveFactory)
  .directive('appUserNotifications', downgradeComponent({ component: UserNotificationsComponent }) as angular.IDirectiveFactory)
  .directive('ucPrefixedEventHandlers', downgradeComponent({ component: PrefixedEventHandlersComponent }) as angular.IDirectiveFactory)
  ;

// Old AngularJS way:
// angular.element(() => {
//   angular.bootstrap(document.body, ["SoracomUserConsole"]);
// });

// replace AngularJS bootstrap with Angular way:
// setAngularLib(angular);app/shared/main/app.ts
// platformBrowserDynamic().bootstrapModule(AppModule);
// angular.element(() => {
//   angular.bootstrap(document.body, ["SoracomUserConsole"]);
// });
