import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { isEqual } from 'lodash-es';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { DEFAULT_INPUT_DEBOUNCE_TIME } from '../../../shared/user_interaction';
import { AxisControlState } from '../harvest-data-viz-type';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

type AxisFormControls = { [key in keyof AxisControlState]: AbstractControl };
type AxisFormGroup = FormGroup & {
  value: AxisControlState;
  controls: AxisFormControls;
  valueChanges: Observable<AxisControlState>;
};

@Component({
  selector: 'app-harvest-chart-yaxis-control',
  templateUrl: './harvest-chart-yaxis-control.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HarvestDataChartYAxisControlComponent implements OnInit {
  private destroyRef = inject(DestroyRef);

  @Input() set value(v: AxisControlState) {
    this.changeControl(v);
    this.form.setValue(v, { emitEvent: false });
  }

  @Output() valueChange = new EventEmitter<AxisControlState>();

  form = new FormGroup({
    autoAdjust: new FormControl(true),
    maxValue: new FormControl({ value: '', disabled: true }),
    minValue: new FormControl({ value: '', disabled: true }),
  } as AxisFormControls) as AxisFormGroup;

  ngOnInit(): void {
    this.form.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap((v) => {
          this.changeControl(v);
        }),
        debounceTime(DEFAULT_INPUT_DEBOUNCE_TIME), // wait 400ms for newer input
        distinctUntilChanged((x, y) => isEqual(x, y))
      )
      .subscribe((v) => {
        // use getRawValue() instead of just the `v`,
        // because as the `v` doesn't include disabled control values
        this.valueChange.emit(this.form.getRawValue() as AxisControlState);
      });
  }

  changeControl(v: AxisControlState) {
    // @ts-expect-error (legacy code incremental fix)
    if (v.autoAdjust && !this.form.controls.maxValue.disabled) {
      // @ts-expect-error (legacy code incremental fix)
      this.form.controls.maxValue.disable({ emitEvent: false });
      // @ts-expect-error (legacy code incremental fix)
      this.form.controls.minValue.disable({ emitEvent: false });
      // @ts-expect-error (legacy code incremental fix)
    } else if (!v.autoAdjust && this.form.controls.maxValue.disabled) {
      // @ts-expect-error (legacy code incremental fix)
      this.form.controls.maxValue.enable({ emitEvent: false });
      // @ts-expect-error (legacy code incremental fix)
      this.form.controls.minValue.enable({ emitEvent: false });
    }
  }
}
