export const template = `<app-user-notifications></app-user-notifications>
<main class="ds-app__main-container x-logs-page-root">
  <header class="ds-banner --simple --indent-small">
    <div>
      <h2>{{ $ctrl.getPageTitleKey() | translate }}</h2>
      <p ng-bind-html="'logs.description' | translate"></p>
    </div>
  </header>
  <div class="alerts">
    <sc-alerts service="$ctrl.alertsService"></sc-alerts>
  </div>
  <form data-testid="query-logs-form" class="ds-controls --indent-small" ng-submit="$ctrl.search()">
    <div>
      <div class="ds-select">
        <select data-testid="service" ng-model="$ctrl.service" ng-options="s.code as s.label for s in $ctrl.services"
          ng-change="$ctrl.updateResourceTypeOptions()"></select>
      </div>
      <div class="ds-select">
        <select data-testid="resource-type" ng-model="$ctrl.resourceType"
          ng-options="rt.code as rt.label for rt in $ctrl.resourceTypes"
          ng-change="$ctrl.updateResourceIdPlaceholder()"></select>
      </div>
      <div class="ds-input">
        <input data-testid="resource-id" type="text" ng-disabled="$ctrl.resourceType === 'all'"
          ng-attr-placeholder="{{ $ctrl.resourceIdPlaceholder }}" ng-model="$ctrl.resourceId" />
      </div>
    </div>
    <div>
      <label class="control-label sr-only">{{ 'logs.query.time' | translate }}</label>
      <sc-date-time-picker
        date-time-msec="$ctrl.from" required="false" field-name='"from"' min-date="$ctrl.min" no-time-zone="true"></sc-date-time-picker>
      <sc-date-time-picker date-time-msec="$ctrl.to" required="false" field-name='"to"'
        min-date="$ctrl.min" no-time-zone="true"></sc-date-time-picker>
      <button class="ds-button --primary" type="submit">
        <span>{{ 'logs.query.search' | translate }}</span>
      </button>
    </div>
  </form>
  <div class="ds-controls --end">
    <sc-pagination
      paginator="$ctrl.paginator"
      ctrl="$ctrl"
      settings="true"
    ></sc-pagination>
  </div>
  <div class="table-responsive table-logs">
    <table class="ds-datatable --striped" data-testid="logs-table">
      <thead>
        <tr>
          <th data-testid="logs-th-time" ng-if='$ctrl.shouldShowColumn("time")'>
            {{ 'logs.columns.time' | translate }}
          </th>
          <th data-testid="logs-th-service" ng-if='$ctrl.shouldShowColumn("service")'>
            {{ 'logs.columns.service' | translate }}
          </th>
          <th data-testid="logs-th-resource-type" ng-if='$ctrl.shouldShowColumn("resource_type")'>
            {{ 'logs.columns.resource_type' | translate }}
          </th>
          <th data-testid="logs-th-resource-id" ng-if='$ctrl.shouldShowColumn("resource_id")'>
            {{ 'logs.columns.resource_id' | translate }}
          </th>
          <th data-testid="logs-th-message" ng-if='$ctrl.shouldShowColumn("message")'>
            {{ 'logs.columns.message' | translate }}
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody data-testid="x-logs-list" ng-if="!$ctrl.isLoading() && $ctrl.hasData()">
        <tr ng-repeat="row in $ctrl.tableData.rows">
          <td data-testid="logs-time" ng-if='$ctrl.shouldShowColumn("time")' sc-date-time="row.obj.time" class="--no-wrap"></td>
          <td ng-class="['logs-service', 'service' + row.obj.service]" ng-if='$ctrl.shouldShowColumn("service")'>
            <span class="--no-wrap" ng-if="row.obj.service">{{ 'logs.values.service.' + row.obj.service | translate }}</span>
          </td>
          <td ng-class="['logs-resource-type', 'resource-type' + row.obj.resourceType]"
            ng-if='$ctrl.shouldShowColumn("resource_type")'>
            <span ng-if="row.obj.resourceType">{{ 'logs.values.resource_type.' + row.obj.resourceType |
              translate }}</span>
          </td>
          <td data-testid="logs-resource-id" ng-if='$ctrl.shouldShowColumn("resource_id")'>
            {{ row.obj.resourceId }}
             <button
              class="ds-button --in-place --icon-filter --left"
              data-testid="logs-filter-by-resource-id"
              ng-click="$ctrl.filterResource(row.obj)"
              ng-if="!$ctrl.doesFilterResource(row.obj)"
            >
              <span>{{ 'logs.columns.filter_by_resource_id' | translate }}</span>
            </button>
          </td>
          <td data-testid="logs-message" ng-if='$ctrl.shouldShowColumn("message")'>
            {{ row.obj.message }}
          </td>
          <td>
            <button class="ds-button --in-place --icon-inspect --left" data-testid="open-raw-data" ng-click="$ctrl.openRawData(row.obj)">
              <span>{{ 'logs.columns.inspect_details' | translate }}</span>
            </button>
          </td>
        </tr>
      </tbody>
      <tbody data-testid="x-no-logs-exist" ng-if="!$ctrl.isLoading() && !$ctrl.hasData()">
        <tr>
          <td colspan="999">
            {{ 'common.table.no_data' | translate }}
          </td>
        </tr>
      </tbody>
      <tbody data-testid="x-is-loading" ng-if="$ctrl.isLoading()">
        <tr>
          <td colspan="999">
            {{ 'common.table.loading' | translate }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</main>`;
