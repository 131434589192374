export const template = `<app-user-notifications></app-user-notifications>
<main class="ds-app__main-container">
  <div class="ds-banner --simple --indent-small">
    <div>
      <h2>{{ 'traffic_volume_ranking.page_header' | translate }}</h2>
      <p>{{ 'traffic_volume_ranking.exemption_from_responsibility' | translate }}</p>
    </div>
  </div>
  
  <sc-alerts class="ds-rows --h-indent-small --gap-small" service="$ctrl.alertsService"></sc-alerts>
  
  <form
    class="ds-controls"
    data-testid="query-traffic-volume-ranking-form"
    name="searchQuery"
    ng-submit="$ctrl.search()"
  >
    
    <div class="ds-bar">
      <input
        class="ds-input --narrow"
        id="limit-number"
        type="number"
        ng-min="$ctrl.limitConstraint.limitMin"
        ng-max="$ctrl.limitConstraint.limitMax"
        ng-model="$ctrl.dataJar.limit"
      />
      <label
        id="limit-number-label"
        for="limit-number"
      >{{ 'traffic_volume_ranking.limit ' | translate }}</label>
      <label class="control-label sr-only">{{ 'traffic_volume_ranking.time' | translate }}</label>
      <sc-date-time-picker
        date-time-msec="$ctrl.dataJar.searchFromMillis"
        required="true"
        field-name="&quot;from&quot;"
        no-time-zone="true"
      ></sc-date-time-picker>

      <p class="form-control-static text-center">-</p>
      <sc-date-time-picker
        date-time-msec="$ctrl.dataJar.searchToMillis"
        required="true"
        field-name="&quot;to&quot;"
        no-time-zone="true"
      ></sc-date-time-picker>

      <button
        class="ds-button --primary"
        type="submit"
        ng-disabled="searchQuery.$invalid"
      >
        <span>{{ 'traffic_volume_ranking.search' | translate }}</span>
      </button>
    </div>
    
  </form>

  <section class="ds-app__main-content ds-rows --indented">
  

    <app-traffic-volume-ranking-chart [chart-data]="$ctrl.chartData"></app-traffic-volume-ranking-chart>
  </section>
</main>`;
