import { LegacyAny } from '@soracom/shared/core';

import { ScRelation } from '../components/paginator';
import {
  LegacyBaseSoracomApiService,
  PaginatableService,
  PaginationService,
  SoracomApiParams,
  TaggableService,
  TagParams,
} from '@user-console/legacy-soracom-api-client';
import { InjectList } from '../core/injectable';
import { LoraGateway } from '../core/lora_gateway';
import { LoraNetworkSet } from '../core/lora_network_set';

// window.app.factory('LoraNetworkSetsService', LoraNetworkSetsService);

export class LoraNetworkSetsService implements TaggableService, PaginatableService<LoraNetworkSet> {
  static $inject: InjectList = ['$log', '$q', 'BaseSoracomApiService', 'PaginationService'];

  resourcePath = 'lora_network_sets';

  constructor(
    private $log: ng.IQService,
    private $q: ng.IQService,
    private soracomApi: LegacyBaseSoracomApiService,
    private PaginationService: PaginationService,
  ) {}

  // @ts-expect-error (legacy code incremental fix)
  list(queryOptions = { limit: 100, last_evaluated_key: null }): Promise<ScRelation<LoraNetworkSet>> {
    const apiParams = {
      method: 'get',
      path: `/v1/${this.resourcePath}`,
      query: {
        limit: queryOptions.limit,
        last_evaluated_key: queryOptions.last_evaluated_key,
      },
    };

    // @ts-expect-error (legacy code incremental fix)
    return this.soracomApi.callApiWithToken(apiParams).then((response: LegacyAny) => {
      const data = response.data.map((element: LegacyAny) => new LoraNetworkSet(element));
      const relation: ScRelation<LoraNetworkSet> = {
        data,
        links: this.PaginationService.getPaginationLinks(response.headers.link),
      };

      return this.$q.resolve(relation);
    });
  }

  get(id: string): Promise<LoraNetworkSet> {
    const apiParams: SoracomApiParams = {
      method: 'get',
      path: `/v1/${this.resourcePath}/${id}`,
    };
    return this.soracomApi.callApiWithToken(apiParams).then((response: LegacyAny) => {
      return new LoraNetworkSet(response.data);
    });
  }

  create(name?: string): Promise<LoraNetworkSet> {
    const apiParams: SoracomApiParams = {
      method: 'post',
      path: `/v1/${this.resourcePath}`,
      contentType: 'application/json',
      body: '',
    };
    if (name) {
      apiParams.body = { tags: { name } };
    }
    return this.soracomApi.callApiWithToken(apiParams).then((response: LegacyAny) => {
      return new LoraNetworkSet(response.data);
    });
  }

  destroy(id: string) {
    const apiParams: SoracomApiParams = {
      method: 'delete',
      path: `/v1/${this.resourcePath}/${id}`,
    };
    return this.soracomApi.callApiWithToken(apiParams);
  }

  listGateways(id: string): Promise<ScRelation<LoraGateway>> {
    const apiParams: SoracomApiParams = {
      method: 'get',
      path: `/v1/${this.resourcePath}/${id}/gateways`,
    };
    return this.soracomApi.callApiWithToken(apiParams).then((response: LegacyAny) => {
      const data = response.data.map((element: LegacyAny) => new LoraGateway(element));
      const relation: ScRelation<LoraGateway> = {
        data,
        links: this.PaginationService.getPaginationLinks(response.headers.link),
      };
      return this.$q.resolve(relation);
    });
  }

  addPermission(id: string, operatorId: string): Promise<LoraNetworkSet> {
    const apiParams: SoracomApiParams = {
      method: 'post',
      path: `/v1/${this.resourcePath}/${id}/add_permission`,
      contentType: 'application/json',
      body: { operatorId: operatorId },
    };
    return this.soracomApi.callApiWithToken(apiParams).then((response: LegacyAny) => {
      return new LoraNetworkSet(response.data);
    });
  }

  revokePermission(id: string, operatorId: string): Promise<LoraNetworkSet> {
    const apiParams: SoracomApiParams = {
      method: 'post',
      path: `/v1/${this.resourcePath}/${id}/revoke_permission`,
      contentType: 'application/json',
      body: { operatorId: operatorId },
    };
    return this.soracomApi.callApiWithToken(apiParams).then((response: LegacyAny) => {
      return new LoraNetworkSet(response.data);
    });
  }

  updateTags(id: string, tags: TagParams[]) {
    const apiParams = {
      method: 'put',
      path: `/v1/${this.resourcePath}/${id}/tags`,
      contentType: 'application/json',
      body: tags,
    };
    return this.soracomApi.callApiWithToken(apiParams);
  }

  deleteTag(id: string, tagName: string) {
    const apiParams = {
      method: 'delete',
      path: `/v1/${this.resourcePath}/${id}/tags/${encodeURIComponent(tagName)}`,
    };
    return this.soracomApi.callApiWithToken(apiParams);
  }
}
