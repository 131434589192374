import { LegacyAny } from '@soracom/shared/core';

import { Taggable, Terminatable } from '@soracom/shared/soracom-platform';
import { BaseModel } from '@soracom/shared/soracom-platform';
import { LoraNetworkSet } from './lora_network_set';

export type LoraGatewayStatus = 'ready' | 'active' | 'instock' | 'terminated';

export class LoraGateway extends BaseModel implements Taggable, Terminatable {
  static resourcePath = 'lora_gateway';

  // @ts-expect-error (legacy code incremental fix)
  gatewayId: string;
  // @ts-expect-error (legacy code incremental fix)
  operatorId: string;

  // @ts-expect-error (legacy code incremental fix)
  address: string;
  // @ts-expect-error (legacy code incremental fix)
  contractEndTime: number;
  // @ts-expect-error (legacy code incremental fix)
  createdTime: number;
  // @ts-expect-error (legacy code incremental fix)
  lastModifiedTime: string;
  // @ts-expect-error (legacy code incremental fix)
  location: { lat: number; lon: number };
  // @ts-expect-error (legacy code incremental fix)
  networkSetId: string;
  // @ts-expect-error (legacy code incremental fix)
  online: boolean;
  // @ts-expect-error (legacy code incremental fix)
  owned: boolean;
  // @ts-expect-error (legacy code incremental fix)
  status: LoraGatewayStatus;
  // @ts-expect-error (legacy code incremental fix)
  terminationEnabled: boolean;
  // @ts-expect-error (legacy code incremental fix)
  private _networkSet: LoraNetworkSet;

  tags: { [id: string]: string };

  constructor(params?: LegacyAny) {
    super();
    this.tags = {};
    if (params) {
      this.gatewayId = params.gatewayId;
      this.operatorId = params.operatorId;

      this.address = params.address;
      this.contractEndTime = params.contractEndTime;
      this.createdTime = params.createdTime;
      this.lastModifiedTime = params.lastModifiedTime;
      this.location = params.location;
      this.networkSetId = params.networkSetId;
      this.online = params.online;
      this.owned = params.owned;
      this.status = params.status;
      this.terminationEnabled = params.terminationEnabled;

      this.tags = params.tags || {};
    }
  }

  get id(): string {
    return this.gatewayId;
  }

  get loraDeviceId(): string {
    return this.gatewayId;
  }

  set loraGatewayId(gatewayId: string) {
    this.gatewayId = gatewayId;
  }

  get name() {
    return this.tags?.name;
  }

  get gatewayType(): string {
    return this.owned ? 'owned' : 'shared';
  }

  get networkSetName(): string {
    if (this.networkSet) {
      return this.networkSet.tags.name;
    } else {
      // @ts-expect-error (legacy code incremental fix)
      return null;
    }
  }

  set networkSet(item: LoraNetworkSet) {
    this._networkSet = item;
    // @ts-expect-error (legacy code incremental fix)
    this.networkSetId = item ? item.networkSetId : null;
  }

  get networkSet(): LoraNetworkSet {
    return this._networkSet;
  }

  isActivated(): boolean {
    return this.status === 'active';
  }

  isDeactivated(): boolean {
    return this.status === 'terminated';
  }

  isReady(): boolean {
    return this.status === 'ready';
  }

  isTerminated(): boolean {
    return this.status === 'terminated';
  }
}
